.news {
    z-index: 2;

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &-sub {
            margin: 0 75px;

            @include grid-media($portrait) {
                margin: 0 24px;
            }
        }
    }

    &__single {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;

        height: 46px;
        overflow: hidden;

        // &--hot {
        //     height: 
        // }

        &:not(:last-child) {
            border-bottom: 1px solid #afc5d5;
        } 



        @include grid-media($portrait) {
            height: 72px;
            &:not(:last-child) {
                border-bottom: 1px solid #afc5d5;
            } 
        }

        .cate, .name, .time {
            display: inline-flex;
            align-items: center;
            color: #000;
            text-decoration: none;

            &:not(.name) {
                justify-content: center;
            }

            @include grid-media($portrait) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        .cate {
            width: 90px;
            position: relative;
            z-index: 2;
            font-size: 14px;

            &:before {
                content: "";
                display: block;
                width: calc(100% - 10px);
                height: calc(100% - 20px);
                background: #d9e5ff;
                position: absolute;
                top: 10px;
                left: 5px;
                z-index: -1;
                border: 1px solid #558cbd;
                border-radius: 4px;
            }

            @include grid-media($portrait) {
                display: none;
            }
        }

        .time {
            width: 90px;

            @include grid-media($portrait) {
                display: none;
            }
        }

        .name {
            width: calc(100% - 90px - 90px);
            justify-content: flex-start;
            padding: 0 14px;

            @include grid-media($portrait) {
                width: 100%;
                padding: 0 0px 0 32px;

                &:before {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #2955b4;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 9px;
                    top: calc(50% - 7px);
                }
            }
        }

        &--hot {
            .cate {
                background: #ffd05f;
                font-weight: bold;
                color: #FFF;
                font-size: 18px;

                &:before {
                    display: none;
                }
            }
            .name {
                background: #d9e5ff;
                font-weight: bold;

                @include grid-media($portrait) {
                    background: transparent;
                    color: #2955b4;
                    &:before {
                        background: #2955b4;
                    }
                }
            }
            .time {
                font-size: 0;
                background: #d9e5ff;
                @include grid-media($portrait) {
                    background: transparent;
                }
            }
        }

        .name {

        }



        &:hover {
            &.news__single--hot {
                .name {
                    color: #000;
                }
            }

            &.news__single {
                .name {
                    color: #912600;
                }
            }
        }
        
    }


    &__single-sub {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;

        // height: 46px;
        overflow: hidden;

        // &--hot {
        //     height: 
        // }

        &:not(:last-child) {
            border-bottom: 1px solid #afc5d5;
        } 



        @include grid-media($portrait) {
            &:not(:last-child) {
                border-bottom: 1px solid #afc5d5;
            } 
        }

        .cate, .name, .time {
            display: inline-flex;
            align-items: center;
            color: #000;
            text-decoration: none;
            justify-content: flex-start;
            padding: 14px 12px;
            text-align: left;

            &.time {
                justify-content: flex-end;
            }

            @include grid-media($portrait) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        .cate {
            width: 160px;
            position: relative;
            z-index: 2;
            font-size: 20px;
            color: #665d51;
            font-weight: 600;

            @include grid-media($portrait) {
                display: none;
            }
        }

        .time {
            width: 100px;
            color: #929191;
            font-size: 16px;

            @include grid-media($portrait) {
                display: none;
            }
        }

        .name {
            width: calc(100% - 160px - 100px);
            justify-content: flex-start;
            padding: 0 14px;
            color: #665d51;
            font-size: 18px;
            line-height: 32px;

            @include grid-media($portrait) {
                font-size: 26px;
                width: 100%;
                padding: 14px 12px 14px 32px;

                &:before {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #2955b4;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 9px;
                    top: calc(50% - 7px);
                }
            }
        }

        &--hot {
            .cate {
                background: #ffd05f;
                font-weight: bold;
                color: #FFF;
                font-size: 18px;

                &:before {
                    display: none;
                }
            }
            .name {
                background: #d9e5ff;
                font-weight: bold;

                @include grid-media($portrait) {
                    background: transparent;
                    color: #2955b4;
                    &:before {
                        background: #2955b4;
                    }
                }
            }
            .time {
                // font-size: 0;
                background: #d9e5ff;
                @include grid-media($portrait) {
                    background: transparent;
                }
            }
        }

        .name {

        }



        &:hover {
            &.news__single-sub--hot {
                .name {
                    color: #000;
                }
            }

            &.news__single-sub {
                .name {
                    color: #912600;
                }
            }
        }
        
    }



    &__content {
        position: relative;
        z-index: 2;
        background: #fafafa;
        padding: 20px;
        margin-top: -100px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100px;
            box-shadow: 0 -40px 30px 0 rgba(0, 0, 0, 0.3);
            top: 0;
            left: 0;
            z-index: -1;

            @include grid-media($mobile) {
                display: none;
            }
        }

        @include grid-media($mobile, $tablet) {
            background: transparent;
            margin-top: 40px;
        }

        &__breadcrumbs {
            ul {
                display: flex;
                justify-content: flex-start;
                li {
                    margin-right: 4px;
                    a {
                        text-decoration: none;
                        .label {
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: bold;
                            color: $gray-darkest;
                        }
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            width: 6px;
                            height: 16px;
                            svg {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                path {
                                    fill: $black;
                                }

                            }
                        }
                    }
                }
            }
        }

        &__title {
            .label {
                font-weight: bold;
                font-size: 30px;
                line-height: 38px;
            }
        }

        &__meta {
            .time {
                color: $gray-lighter;
                line-height: 16px;
                font-size: 14px;
                vertical-align: text-top;
                margin-right: 16px;
            }
        }
    }


    &__page {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 24px 0;

        ul {
            display: flex;
            justify-self: center;
            li {
                margin: 0 8px;

                &, .page, .text {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 42px;
                }

                .prev {
                    font-size: 0;
                    @include sprite($page-prev);
                }
                .next {
                    font-size: 0;
                    @include sprite($page-next);
                }
                .page {
                    width: 42px;
                    height: 42px;
                    color: #5b5b5b;
                    font-weight: bold;
                    text-decoration: none;



                    &.active {
                        background: #f3f3eb;
                        border: 1px solid #dfdfdf;
                    }

                    &:hover {
                        position: relative;
                        z-index: 2;
                        &:before {
                            content: "";
                            display: block;
                            width: 80%;
                            height: 80%;
                            position: absolute;
                            z-index: -1;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            border-width: 1px;
                            border-style: solid;
                            border-color: #dfdfdf;
                            background: rgba(#f3f3eb, .2);
                        }
                    }
                }

                .text {
                    height: 42px;
                    width: 42px;
                }
            }
        }
    }

    .swiper-container-newsList {
        .swiper-wrapper {
            transition-duration: .5s;
            transition-timing-function: $ani-easeInOutBack;
            .swiper-slide {
                display: flex;
                justify-content: center;
            }
        }
        .swiper-button {
            &-next, &-prev {
                &-newsList {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 80px;

                    background: rgba(#fff, .75);
                    transition: background-color .2s $ani-easeInCubic;

                    @include grid-media($mobile) {
                        height: 40px;
                    }

                    &:before, &:after {
                        display: none;
                    }

                    &:hover {
                        background: rgba(#fff, 1);
                    }

                    .icon {
                        svg {
                            transform-origin: center center;
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
            &-next {
                &-newsList {
                    right: 0;
                }
            }
            &-prev {
                &-newsList {
                    left: 0;
                    .icon {
                        svg {
                            transform: scaleX(-1);
                        }
                    }
                }
            }
        }
    }
}