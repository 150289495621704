.header {
    position: relative;
    width: 100%;
    height: 1252px;
    // max-height: 100vh;
    background: #e2d9c5;
    pointer-events: none;
    // padding-top: 84px;

    @include grid-media($portrait) {
        height: 1200px;
        padding-top: 0px;
    }

    &--sub {
        @include grid-media($portrait) {
            height: 160px;
        }
    }

    &--norank {
        margin-bottom: 300px;
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }

        video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
        height: 100%;


        pointer-events: all;

        .trailer { 
            display: block;
            pointer-events: all;
            position: absolute;
            top: 380px;
            left: calc(50% + 540px);
            @include sprite($video);

            &:hover {
                filter: brightness(120%);
            }

            @include grid-media($portrait) {
                display: none;
            }
        }
    }
}