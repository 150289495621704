
.background {
    &-base {
        background: $base-normal;
        &-darker {
            background: $base-darker;
        }
        &-darkest {
            background: $base-darkest;
        }
        &-lighter {
            background: $base-lighter;
        }
        &-lightest {
            background: $base-lightest;
        }
    }

    // &-base2 {
    //     background: $base2-normal;
    //     &-darker {
    //         background: $base2-darker;
    //     }
    //     &-darkest {
    //         background: $base2-darkest;
    //     }
    //     &-lighter {
    //         background: $base2-lighter;
    //     }
    //     &-lightest {
    //         background: $base2-lightest;
    //     }
    // }

    // &-base3 {
    //     background: $base3-normal;
    //     &-darker {
    //         background: $base3-darker;
    //     }
    //     &-darkest {
    //         background: $base3-darkest;
    //     }
    //     &-lighter {
    //         background: $base3-lighter;
    //     }
    //     &-lightest {
    //         background: $base3-lightest;
    //     }
    // }

    &-white {
        background: $white;
    }
    &-black {
        background: $black;
    }
}

.color {
    &-base {
        color: $base-normal;
        &-darker {
            color: $base-darker;
        }
        &-darkest {
            color: $base-darkest;
        }
        &-lighter {
            color: $base-lighter;
        }
        &-lightest { 
            color: $base-lightest;
        }
    }

    // &-base2 {
    //     color: $base2-normal;
    //     &-darker {
    //         color: $base2-darker;
    //     }
    //     &-darkest {
    //         color: $base2-darkest;
    //     }
    //     &-lighter {
    //         color: $base2-lighter;
    //     }
    //     &-lightest {
    //         color: $base2-lightest;
    //     }
    // }

    // &-base3 {
    //     color: $base3-normal;
    //     &-darker {
    //         color: $base3-darker;
    //     }
    //     &-darkest {
    //         color: $base3-darkest;
    //     }
    //     &-lighter {
    //         color: $base3-lighter;
    //     }
    //     &-lightest {
    //         color: $base3-lightest;
    //     }
    // }

    &-white {
        color: $white;
    }
    &-black {
        color: $black;
    }
}

// VISIBLE

.visible {
    z-index: 2;
}

// ALIGN

.width {
    &--100p {
        width: 100%;
    }
    &--50p {
        width: 50%;
    }
    @for $i from 1 through 10 {
        &--#{$i} {
            margin-left: #{$i * 10}px;
            margin-right: #{$i * 10}px;
        }
    }
}

.margin-horizonal {
    @for $i from 1 through 10 {
        &--#{$i} {
            margin-left: #{$i * 10}px;
            margin-right: #{$i * 10}px;
        }
    }
}

.align {
    display: flex;
    width: 100%;
    &--center {
        justify-content: center;
    }
    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }
    &--middle {
        height: 100%;
        align-items: center;
        flex-wrap: wrap;
    }
    &--top {
        height: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    &--bottom {
        height: 100%;
        align-items: flex-end;
        flex-wrap: wrap;
    }
}

.space {
    width: 100%;
    display: block;

    @for $i from 1 through 10 {
        &--#{$i}0 {
            height: #{$i * 10}px;
        }
    }
}

.placeholder {
    &--navigation {
        height: 68px;
    }
}

.popup-opened {
    overflow: hidden;
}

.loadajax-inform {
    width: 90%;
    margin: 10px auto;
    border: 1px solid rgba(#547fd6, .6);
    background: rgba(#547fd6, .1);
    color: #4c76df;
    padding: 20px;
    text-align: center;
}

.news__page {
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin-top: 25px;
    font-family: Roboto;
    > ul {
        display: inline-block;
        margin: 0 auto;
        clear: both;
        > li {
            float: left;
            margin: 0 5px;
            > a {
                display: block;
                color: #5b5b5b;
                padding: 5px 0;
                min-width: 37px;
                height: 36px;
                // border: 1px solid #071067;
                border-right: 0;
                text-align: center;
            }
            &:first-child,
            &:last-child,
            &.prev,
            &.next
            {
                a {
                    border: 1px solid #dfdfdf;
                }               
            }
            &.active, &:hover {
                a {
                    background: #f3f3eb;
                    border: 1px solid #dfdfdf;
                }
            }
        }
    }
}