.form-control {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
    margin-bottom: 10px;

    &:not(:last-child) {
    }


    .error { 
        position: absolute;
        display: none;
        padding: 6px;
        background: #ff3333;
        color: #fff;
        font-weight: normal;
        font-size: 11px;
        // width: 100%;
        top: 100%;
        right: 0;
        box-sizing: border-box;
        z-index: 3;

        &:before {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border-width: 3px;
            border-style: solid;
            border-color: transparent #ff3333 #ff3333 transparent;
            position: absolute;
            bottom: calc(100% - 3px);
            right: 10px;
            transform: rotate(225deg);
        }
    }

    .error-full { 
        display: none;
        padding: 16px 6px;
        background: rgba(#ff3333, .1);
        color: darken(#ff3333, 20%);
        border: 1px solid rgba(darken(#ff3333, 20%), .4);
        font-weight: normal;
        font-size: 14px;
        width: 100%;
        top: 100%;
        right: 0;
        box-sizing: border-box;
        z-index: 3;
    }

    &--error {
        .error {
            display: inline-block;
        }
        .error-full {
            display: block;
        }
    }

    .available {
        position: absolute;
        display: none;
        right: 10px;
        bottom: 8px;
        .icon {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &--available {
        .available {
            display: block;
        }
    }

    // FILE LOGO

    input[type="file"] {
        display: none;

        & + label {
            display: block;
            border: 1px dashed rgba(#fafafa, .5);
            box-sizing: border-box;
            width: 150px;
            height: 150px;
            padding: 28px 18px;
            cursor: pointer;

            .label {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: $gray-lighter;
                opacity: 0.7;
                text-align: center;
            }

            .icon {
                display: block;
                text-align: center;
                margin-bottom: 10px;
                svg {
                    height: 32px;
                    width: auto;
                }
            }

            & + .imageInput {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 150px;
                height: 150px;
                overflow: hidden;
                background: #242424;

                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                }
            }
        }

        &.active + label {
            background: rgba(#000, .6);
            opacity: 0;
            transition: opacity .2s;

            .label {
                color: #fff;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    // TEXT 

    input[type="text"], input[type="password"], input[type="email"], input[type="number"] {
        width: 84%;
        order: 2;
        background: #f5ead5;
        border: 1px solid #c9ab78;
        box-sizing: border-box;
        border-radius: 0px;
        height: 55px;
        padding: 10px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: border .2s;
        cursor: text;
        color: #7d6336;
        font-size: 27px;
        font-family: BeaufortForLoL;

        &:focus {
            outline: none;
            border: 1px solid #bf9979;
            box-shadow: inset 0 0 10px 2px rgba(#707546, .2);
        }

        &::placeholder {
            opacity: 0;
            pointer-events: none;
            color: #c9aa75;
            font-size: 27px;
            line-height: 32px;
            font-family: BeaufortForLoL;
            text-align: center;
            transition: opacity .2s;
        }


        & + label {
            order: 1;
            position: absolute;
            opacity: 1;
            pointer-events: all;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: opacity .2s;
            .label {
                color: #c9aa75;
                font-size: 27px;
                font-family: BeaufortForLoL;
                text-align: center;
            }
        }

        &:focus + label {
            opacity: 0;
            pointer-events: none;
        }

        &:focus {
            &::placeholder {
                opacity: 0.6;
            }
        }
    }

    input[type="checkbox"], input[type="radio"] {

        order: 1;
        background: #c4c4c4;
        border: 1px solid #CFCFCF;
        box-sizing: border-box;
        border-radius: 0;
        width: 10px;
        height: 10px;
        padding: 9px;
        margin-right: 10px;
        -webkit-appearance: none;

        transition: all .2s; 
        transform: scale(.8);
        transform-origin: center center;

        &, &:focus {
            outline: none;
        }

        &:checked {
            transform: scale(1);
            background: transparent;
            border: 1px solid $base-normal;
            box-shadow: 0 0 0 1px $base-normal;
            &:before {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                position: absolute;
                background: $base-normal;
                top: 2px;
                left: 2px;
            }
        }

        &:disabled {
            background: $gray-darker;
        }


        & + label {
            width: calc(100% - 40px);
            order: 2;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba($white, .7);
            cursor: pointer;
            // margin-bottom: $input-space-label;

            a {
                color: rgba($white, .7);
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    input[type="radio"] {
        border-radius: 12px;

        &:checked {
            &:before {
                border-radius: 12px;
            }
        }
    }

    &--search {
        input[type="text"] {
            background: none;
            border: none;
            border-bottom: 1px solid rgba(#fff, .5);
            border-radius: 0;
            color: #fff;
            width: 320px;
            height: 60px;
            font-size: 20px;

            &:focus {
                border: none;
                border-bottom: 1px solid rgba(#fff, .9);
                box-shadow: none;
            }
        }
    }

    // SELECT

    select {

        & + .select2 {
            order: 2;
            width: 100% !important;

            // width: 100%;
            .select2-selection {
                background: #FFFFFF;
                border: 2px solid #CFCFCF;
                box-sizing: border-box;
                border-radius: 0px;
                height: 40px;
                padding: 10px;
    
                transition: border .2s; 

                &__rendered {
                    line-height: 18px;
                    color: $gray-lighter;
                    padding: 0;
                }

                &__arrow {
                    width: 15px;
                    height: 10px;
                    background: url(../svg/arrow-down.svg) 0 0 no-repeat;
                    transition: transform .2s;
                    position: absolute;
                    top: calc(50% - 10px / 2);
                    right: calc(10px + 5px / 2);
                    b {
                        display: none;
                    }
                }
            }

            &.select2-container {
                &--open {
                    .selection {
                        .select2-selection {
                            &__arrow {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                &--focus {
                    .selection {
                        .select2-selection {
                            border: 1px solid #2596E8;
                            box-shadow: 0 0 0 1px #2596E8;
                        }
                    }
                }
            }


            & + label {
                order: 1;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: rgba($gray-lighter, 1);
                margin-bottom: 8px;
            }
        }
    }

    // recaptcha 

    .g-recaptcha {
        height: 78px;
        margin: 0 auto;
    }

    // SUBMIT 



    button {
        width: 84%;
        height: 80px;
        font-family: $font-secondary;
        font-weight: bold;
        font-stretch: condensed;
        text-transform: uppercase;
        padding: 8px 30px;
        border: none;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;
        margin: 0 auto;
        background: #861c00;
        transition: all .2s;
        &:hover {
            background: #9b0000;
        }


        .label {
            display: inline-block;
            font-family: Oswald;
            font-size: 34px;
            text-transform: uppercase;
            color: #ffe3b4;
            position: relative;
            padding: 0 20px;
            

            &:before, &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: #ffe3b4;
                position: absolute;
                top: calc(50% - 2px);
                transform: rotate(45deg);
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        } 
    }



    &--search {
        button {
            width: auto;
            height: 40px;
            color: #fff;
            background: rgba(#000, .2);
            border: 1px solid rgba(#fff, .5);
            transition: all .2s;

            font-weight: 300;
            font-size: 18px;
            line-height: 29px;
            padding: 0 20px;
            cursor: pointer;

            &:hover {
                background: rgba(#000, .8);
                border: 1px solid rgba(#fff, .9);
            }
        }
    }




}



// FORM LIBS


.select2-results {
    &__option {
        padding: 10px 20px !important;
        color: #555 !important; 

        &[aria-selected="true"] {
            background: #fcfcfc !important;

            &.select2-results__option--highlighted {
                background: #b8e2fd !important;
            }
        }

        &--highlighted  {
            background: #b8e2fd !important;
        }
    }
}