@import "css-reset-and-normalize";
@import "bourbon-neat";


$hamburger-padding-x        : 8px;
$hamburger-padding-y        : 11px;
$hamburger-layer-width      : 28px;
$hamburger-layer-height     : 4px;
$hamburger-layer-spacing    : 5px;
$hamburger-layer-color      : rgba(#FFF, 1);
@import "hamburgers/_sass/hamburgers/hamburgers";


@import "sprites/sprites-full";

@import "configs/design-system";
@import "configs/fonts"; 

@import "configs/mixins/grid";
@import "configs/mixins/layer";
@import "configs/mixins/border_bevel";

@import "configs/support";
@import "configs/layout"; 
@import "configs/article"; 

// CUSTOM 

// @import "components/tournament_selector";
@import "components/navigation"; 
@import "components/sidebar"; 
@import "components/header"; 
@import "components/general"; 
@import "components/rank"; 
@import "components/characters";  
@import "components/gallery";  
@import "components/subpage";  
// @import "components/content"; 
@import "components/store"; 
@import "components/footer"; 
@import "components/form";  
// @import "components/promotion"; 


@import "components/popup"; 
@import "elements/typography";
@import "elements/button";  
@import "elements/border";  
@import "elements/news";  
@import "elements/events";  
@import "elements/char";  