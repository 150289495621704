.navigation {
    width: 100%;
    pointer-events: all;
    position: absolute;
    z-index: 5;
    height: 84px;
    transition: height .2s $ani-easeOutSine;
    
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;

        background: rgba(#FFF, .75);

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
    }

    nav:not(.compact) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                position: relative;
                margin:0 4px;
                @include grid-media($mobile, $tablet) {
                    margin: 0 2px;
                }
                a {
                    &:hover, &.active, &:focus {
                        & + ul {
                            transform: scale(1, 1);
                            max-height: 9999px;
                        }
                    }
                }
                ul {
                    display: flex;
                    align-items: center;
                    z-index: 6;
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 240px / 2);
                    width: 240px;
                    background: rgba($white, 1);
                    flex-wrap: wrap;
                    transform-origin: top center;
                    transform: scale(.8, 0);
                    transition: transform .2s $ani-easeOutCirc;

                    @include grid-media($mobile, $tablet) {
                        transform: scale(0, .8);
                        transform-origin: right center;
                        // position: relative;
                        max-height: 0;
                        overflow: hidden;
                        left: auto;
                        right: 100%;
                        top: 0%;

                    }

                    &:hover {
                        transform: scale(1, 1);
                        max-height: 9999px;
                    }

                    li {
                        width: 100%;
                        margin:0 0px;
                        &:not(:last-child) {
                            border-bottom: 1px solid rgba(#000, .1);
                        }

                    }
                }

            }

            & > li:not(:last-child) {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    @include sprite($nav-split);

                    position: absolute;
                    top: 43%;
                    left: 99%;
                }

                li {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    nav.compact {
        display: none;
        height: 84px;

    }

    @include grid-media($mobile, $tablet) {
        nav.compact {
            display: block;

            ul {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                li:first-child {
                    margin-right: auto;
                }

                li:not(:first-child) {
                    margin: 0 4px;
                }
            }
        }

        nav:not(.compact) {
            flex-wrap: wrap;
            width: 300px;
            margin-left: auto;
            background: #FFF; 
            box-shadow: 0 0 30px 0px rgba(#000, .4);

            transform: scale(.8, 0);   
            transition: transform .2s;
            transform-origin: top right;
            height: 0;
            
            &.active {
                transform: scale(1, 1);
                height: 100%;
            }

            ul {
                flex-wrap: wrap;
                width: 100%;
                li {
                    width: 100%;
                    border-bottom: 1px solid #CCC;

                    ul {
                        box-shadow: 0 0 30px 0px rgba(#000, .4);
                    }
                }

                & > li:not(:last-child) {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &--pwth {


        nav {
            .music, .install, .facebook {
                .label {
                    font-size: 0;
                }

                &:hover {
                    filter: brightness(120%);
                };
            }
            .music {
                display: block;
                @include sprite($music-mute);
                &.active {
                    @include sprite($music);
                }

                @include grid-media($portrait) {
                    justify-self: center;
                    display: none;
                }
            }
 
            .appicon {
                display: block;
                width: 342px;
                height: 84px;
                background: url(../../dist/assets/images/common/appicon-mb.png);
                background-repeat: no-repeat;
                background-position: left center;
                display: flex;
                justify-content: flex-end;

                .label {
                    font-size: 30px;
                    font-weight: bold;
                    color: #2e53a1;
                }
            }

            .install {
                @include sprite($download-top);
            }

            .facebook {
                @include sprite($facebook-top);
            }

            .hamburger {
                background-color: #1e31a2;
                padding: 16px 14px;

            }


        }


    }

}