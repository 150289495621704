@font-face {
    font-family: Roboto;
    src:    url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Roboto;
    src:    url('../fonts/Roboto/Roboto-Regular.ttf');
    font-weight: normal;  
} 


@font-face {
    font-family:    SukhumvitSet;
    src:            url('../fonts/SukhumvitSet/SukhumvitSet-Text.ttf');
    font-weight:    300;  
} 

@font-face {
    font-family:    SukhumvitSet;
    src:            url('../fonts/SukhumvitSet/SukhumvitSet-Bold.ttf');
    font-weight:    700;  
} 

@font-face {
    font-family:    SukhumvitSet;
    src:            url('../fonts/SukhumvitSet/SukhumvitSet-SemiBold.ttf');
    font-weight:    600;   
} 

@font-face {
    font-family:    SukhumvitSet;
    src:            url('../fonts/SukhumvitSet/SukhumvitSet-Light.ttf');
    font-weight:    200;  
} 

@font-face {
    font-family:    Cinzel;
    src:            url('../fonts/Cinzel/Cinzel-Regular.ttf');
    font-weight:    300;  
} 

@font-face {
    font-family:    Cinzel;
    src:            url('../fonts/Cinzel/Cinzel-Bold.ttf');
    font-weight:    600;  
} 

body {
    font-family: Roboto, sans-serif; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;  
}