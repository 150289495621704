@mixin cxGrid($neat-config) {
	@include grid-container;
	@include grid-collapse($neat-config); 
	width: initial;
	display: flex;
	flex-wrap: wrap;

	.col-mobile, .col-tablet, .col-desktop {
		&-0 {
			// display: none;
		}
	}

	@include grid-media($mobile) {
		@for $i from 1 through 12 {
			& > .col-mobile-#{$i} {
				@include grid-column($i, $neat-config);
			}
		}
	}

	@include grid-media($tablet) {
		@for $i from 1 through 12 { 
			& > .col-tablet-#{$i} {
				@include grid-column($i, $neat-config);
			}
		}
	}

	@include grid-media($desktop) {
		@for $i from 1 through 12 {
			& > .col-desktop-#{$i} {
				@include grid-column($i, $neat-config);
			}
		}
	}


	//------

	@include grid-media($mobile) {
		@for $i from 1 through 12 {
			& > .col-mobile-push-#{$i} {
				@include grid-push($i, $neat-config);
			}
		}
	}

	@include grid-media($tablet) {
		@for $i from 1 through 12 { 
			& > .col-tablet-push-#{$i} {
				@include grid-push($i, $neat-config);
			}
		}
	}

	@include grid-media($desktop) {
		@for $i from 1 through 12 {
			& > .col-desktop-push-#{$i} {
				@include grid-push($i, $neat-config);
			}
		}
	}

	//--------


	@for $i from 1 through 12 {
		@include grid-media($mobile) {
			& > .col-mobile-order-#{$i} {
				order: #{$i};
			}
		}
		@include grid-media($tablet) {
			& > .col-tablet-order-#{$i} {
				order: #{$i};
			}
		}
		@include grid-media($desktop) {
			& > .col-desktop-order-#{$i} {
				order: #{$i};
			}
		}
	}
}