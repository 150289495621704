.popup {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    // display: none;

    &__background, &__background--unclosable { 
        opacity: 0;
        position: absolute;
        background: rgba(#000, .8); 
        width: 100%;
        height: 100vh;
        transition: opacity 1s;
    }

    &__content {
        opacity: 0;
        position: absolute;
        // background: #fff;
        z-index: 2;
        transition: all .2s;
        // transform: translateY(-100vh);
        transform: scale(0);
    }

    &__toolbar {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: rgba(#000, .1);
        transition: all .2s;
        padding: 5px;
        display: flex;
        justify-content: flex-end;

        .close {
            width: 50px;
            height: 50px;
            display: flex;
            background: #000;
            justify-content: center;
            align-items: center;
            position: absolute;
            // @include sprite($close);
            svg {
                display: block;
                width: 32px;
                height: 32px;
                path:not(:last-child) {
                    fill: $white;
                }
                path:last-child {
                    stroke: $white;
                }
            }
        }
    }

    &.active {
        opacity: 1;
        pointer-events: all;

        .popup__background, .popup__background--unclosable {
            opacity: 1;
        }
        .popup__content {
            opacity: 1;
            transform: scale(1);
        }
        .popup__toolbar {
            opacity: 1;
        }
    }
}

.popup {
    &.searchbox {
        .popup {
            &__background {
                // background: rgb(0,0,0);
                // background: linear-gradient(0deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,.8) 50%, rgba(0,0,0,.3) 100%);
            }

            &__content {

            }
        }
    }
}

.popup--open-image {
    .popup__content {
        // width: 80%;
        // height: 60%;
        .image {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.popup--open-video {
    .popup__content {
        width: 1200px;
        height: 670px;

        @include grid-media($portrait) {
            width: 680px;
            height: 385px;
        }
        
        .embed {
            width: 100%;
            height: 100%;
            iframe {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}