@-ms-viewport {
  width: device-width; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

*, ::after, ::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  line-height: 1;
  font-family: sans-serif;
  text-align: left; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::after, blockquote::before, q::after, q::before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

th {
  text-align: inherit; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

code, kbd, pre, samp {
  font-family: monospace,monospace; }

pre {
  overflow: auto;
  -ms-overflow-style: scrollbar; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

fieldset {
  min-width: 0; }

legend {
  max-width: 100%;
  white-space: normal;
  color: inherit;
  display: block; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

output {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 11px 8px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: white; }

.hamburger-box {
  width: 28px;
  height: 22px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 28px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 56px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 56px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 56px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 56px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 56px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 56px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -9px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-5.6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-5.6px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(5.6px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(5.6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-5.6px, -7px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-5.6px, 7px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(5.6px, -7px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(5.6px, 7px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 9px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 18px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -18px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 9px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 18px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -18px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 9px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -56px;
      top: -56px;
      transform: translate3d(56px, 56px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -56px;
      top: -56px;
      transform: translate3d(-56px, 56px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 9px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -56px;
      top: 56px;
      transform: translate3d(56px, -56px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -56px;
      top: 56px;
      transform: translate3d(-56px, -56px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 9px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 18px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-4px, -5px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -18px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 9px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 18px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(4px, -5px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -18px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 9px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 9px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 9px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: bold; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: normal; }

@font-face {
  font-family: SukhumvitSet;
  src: url("../fonts/SukhumvitSet/SukhumvitSet-Text.ttf");
  font-weight: 300; }

@font-face {
  font-family: SukhumvitSet;
  src: url("../fonts/SukhumvitSet/SukhumvitSet-Bold.ttf");
  font-weight: 700; }

@font-face {
  font-family: SukhumvitSet;
  src: url("../fonts/SukhumvitSet/SukhumvitSet-SemiBold.ttf");
  font-weight: 600; }

@font-face {
  font-family: SukhumvitSet;
  src: url("../fonts/SukhumvitSet/SukhumvitSet-Light.ttf");
  font-weight: 200; }

@font-face {
  font-family: Cinzel;
  src: url("../fonts/Cinzel/Cinzel-Regular.ttf");
  font-weight: 300; }

@font-face {
  font-family: Cinzel;
  src: url("../fonts/Cinzel/Cinzel-Bold.ttf");
  font-weight: 600; }

body {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.background-base {
  background: #1e2d62; }
  .background-base-darker {
    background: #0c1227; }
  .background-base-darkest {
    background: black; }
  .background-base-lighter {
    background: #30489d; }
  .background-base-lightest {
    background: #506bc9; }

.background-white {
  background: #FFFFFF; }

.background-black {
  background: #000000; }

.color-base {
  color: #1e2d62; }
  .color-base-darker {
    color: #0c1227; }
  .color-base-darkest {
    color: black; }
  .color-base-lighter {
    color: #30489d; }
  .color-base-lightest {
    color: #506bc9; }

.color-white {
  color: #FFFFFF; }

.color-black {
  color: #000000; }

.visible {
  z-index: 2; }

.width--100p {
  width: 100%; }

.width--50p {
  width: 50%; }

.width--1 {
  margin-left: 10px;
  margin-right: 10px; }

.width--2 {
  margin-left: 20px;
  margin-right: 20px; }

.width--3 {
  margin-left: 30px;
  margin-right: 30px; }

.width--4 {
  margin-left: 40px;
  margin-right: 40px; }

.width--5 {
  margin-left: 50px;
  margin-right: 50px; }

.width--6 {
  margin-left: 60px;
  margin-right: 60px; }

.width--7 {
  margin-left: 70px;
  margin-right: 70px; }

.width--8 {
  margin-left: 80px;
  margin-right: 80px; }

.width--9 {
  margin-left: 90px;
  margin-right: 90px; }

.width--10 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-horizonal--1 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-horizonal--2 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-horizonal--3 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-horizonal--4 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-horizonal--5 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-horizonal--6 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-horizonal--7 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-horizonal--8 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-horizonal--9 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-horizonal--10 {
  margin-left: 100px;
  margin-right: 100px; }

.align {
  display: flex;
  width: 100%; }
  .align--center {
    justify-content: center; }
  .align--left {
    justify-content: flex-start; }
  .align--right {
    justify-content: flex-end; }
  .align--middle {
    height: 100%;
    align-items: center;
    flex-wrap: wrap; }
  .align--top {
    height: 100%;
    align-items: flex-start;
    flex-wrap: wrap; }
  .align--bottom {
    height: 100%;
    align-items: flex-end;
    flex-wrap: wrap; }

.space {
  width: 100%;
  display: block; }
  .space--10 {
    height: 10px; }
  .space--20 {
    height: 20px; }
  .space--30 {
    height: 30px; }
  .space--40 {
    height: 40px; }
  .space--50 {
    height: 50px; }
  .space--60 {
    height: 60px; }
  .space--70 {
    height: 70px; }
  .space--80 {
    height: 80px; }
  .space--90 {
    height: 90px; }
  .space--100 {
    height: 100px; }

.placeholder--navigation {
  height: 68px; }

.popup-opened {
  overflow: hidden; }

.loadajax-inform {
  width: 90%;
  margin: 10px auto;
  border: 1px solid rgba(84, 127, 214, 0.6);
  background: rgba(84, 127, 214, 0.1);
  color: #4c76df;
  padding: 20px;
  text-align: center; }

.news__page {
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-top: 25px;
  font-family: Roboto; }
  .news__page > ul {
    display: inline-block;
    margin: 0 auto;
    clear: both; }
    .news__page > ul > li {
      float: left;
      margin: 0 5px; }
      .news__page > ul > li > a {
        display: block;
        color: #5b5b5b;
        padding: 5px 0;
        min-width: 37px;
        height: 36px;
        border-right: 0;
        text-align: center; }
      .news__page > ul > li:first-child a, .news__page > ul > li:last-child a, .news__page > ul > li.prev a, .news__page > ul > li.next a {
        border: 1px solid #dfdfdf; }
      .news__page > ul > li.active a, .news__page > ul > li:hover a {
        background: #f3f3eb;
        border: 1px solid #dfdfdf; }

* {
  outline: none; }

.wrapper {
  transform-origin: top left; }
  .wrapper--fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 50; }
  .wrapper.wrapperScale {
    width: 2100px;
    height: 1220px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .wrapper.wrapperScale {
        width: 768px;
        height: 400px; } }

.container {
  display: block;
  margin: 0 auto;
  width: 1200px;
  max-width: 96%; }
  .container.container--full {
    width: 96%; }
    @media (max-width: 767px) {
      .container.container--full {
        width: 96%; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      .container.container--full {
        width: 96%; } }
    @media (min-width: 1280px) {
      .container.container--full {
        width: 96%; } }

@media (max-width: 767px) {
  .row {
    margin-left: -6px;
    margin-right: -6px;
    width: calc(100% + 12px);
    width: initial;
    display: flex;
    flex-wrap: wrap; }
    .row::after {
      clear: both;
      content: "";
      display: block; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-1 {
      width: calc(8.33333% - 6.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-2 {
      width: calc(16.66667% - 7px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-3 {
      width: calc(25% - 7.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-4 {
      width: calc(33.33333% - 8px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-5 {
      width: calc(41.66667% - 8.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-6 {
      width: calc(50% - 9px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-7 {
      width: calc(58.33333% - 9.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-8 {
      width: calc(66.66667% - 10px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-9 {
      width: calc(75% - 10.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-10 {
      width: calc(83.33333% - 11px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-11 {
      width: calc(91.66667% - 11.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-mobile-12 {
      width: calc(100% - 12px);
      float: left;
      margin-left: 6px; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-1 {
      width: calc(8.33333% - 6.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-2 {
      width: calc(16.66667% - 7px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-3 {
      width: calc(25% - 7.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-4 {
      width: calc(33.33333% - 8px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-5 {
      width: calc(41.66667% - 8.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-6 {
      width: calc(50% - 9px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-7 {
      width: calc(58.33333% - 9.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-8 {
      width: calc(66.66667% - 10px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-9 {
      width: calc(75% - 10.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-10 {
      width: calc(83.33333% - 11px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-11 {
      width: calc(91.66667% - 11.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-tablet-12 {
      width: calc(100% - 12px);
      float: left;
      margin-left: 6px; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-1 {
      width: calc(8.33333% - 6.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-2 {
      width: calc(16.66667% - 7px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-3 {
      width: calc(25% - 7.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-4 {
      width: calc(33.33333% - 8px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-5 {
      width: calc(41.66667% - 8.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-6 {
      width: calc(50% - 9px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-7 {
      width: calc(58.33333% - 9.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-8 {
      width: calc(66.66667% - 10px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-9 {
      width: calc(75% - 10.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-10 {
      width: calc(83.33333% - 11px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-11 {
      width: calc(91.66667% - 11.5px);
      float: left;
      margin-left: 6px; }
    .row > .col-desktop-12 {
      width: calc(100% - 12px);
      float: left;
      margin-left: 6px; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-push-1 {
      margin-left: calc(8.33333% - 6.5px + 12px); }
    .row > .col-mobile-push-2 {
      margin-left: calc(16.66667% - 7px + 12px); }
    .row > .col-mobile-push-3 {
      margin-left: calc(25% - 7.5px + 12px); }
    .row > .col-mobile-push-4 {
      margin-left: calc(33.33333% - 8px + 12px); }
    .row > .col-mobile-push-5 {
      margin-left: calc(41.66667% - 8.5px + 12px); }
    .row > .col-mobile-push-6 {
      margin-left: calc(50% - 9px + 12px); }
    .row > .col-mobile-push-7 {
      margin-left: calc(58.33333% - 9.5px + 12px); }
    .row > .col-mobile-push-8 {
      margin-left: calc(66.66667% - 10px + 12px); }
    .row > .col-mobile-push-9 {
      margin-left: calc(75% - 10.5px + 12px); }
    .row > .col-mobile-push-10 {
      margin-left: calc(83.33333% - 11px + 12px); }
    .row > .col-mobile-push-11 {
      margin-left: calc(91.66667% - 11.5px + 12px); }
    .row > .col-mobile-push-12 {
      margin-left: calc(100% - 12px + 12px); } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-push-1 {
      margin-left: calc(8.33333% - 6.5px + 12px); }
    .row > .col-tablet-push-2 {
      margin-left: calc(16.66667% - 7px + 12px); }
    .row > .col-tablet-push-3 {
      margin-left: calc(25% - 7.5px + 12px); }
    .row > .col-tablet-push-4 {
      margin-left: calc(33.33333% - 8px + 12px); }
    .row > .col-tablet-push-5 {
      margin-left: calc(41.66667% - 8.5px + 12px); }
    .row > .col-tablet-push-6 {
      margin-left: calc(50% - 9px + 12px); }
    .row > .col-tablet-push-7 {
      margin-left: calc(58.33333% - 9.5px + 12px); }
    .row > .col-tablet-push-8 {
      margin-left: calc(66.66667% - 10px + 12px); }
    .row > .col-tablet-push-9 {
      margin-left: calc(75% - 10.5px + 12px); }
    .row > .col-tablet-push-10 {
      margin-left: calc(83.33333% - 11px + 12px); }
    .row > .col-tablet-push-11 {
      margin-left: calc(91.66667% - 11.5px + 12px); }
    .row > .col-tablet-push-12 {
      margin-left: calc(100% - 12px + 12px); } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-push-1 {
      margin-left: calc(8.33333% - 6.5px + 12px); }
    .row > .col-desktop-push-2 {
      margin-left: calc(16.66667% - 7px + 12px); }
    .row > .col-desktop-push-3 {
      margin-left: calc(25% - 7.5px + 12px); }
    .row > .col-desktop-push-4 {
      margin-left: calc(33.33333% - 8px + 12px); }
    .row > .col-desktop-push-5 {
      margin-left: calc(41.66667% - 8.5px + 12px); }
    .row > .col-desktop-push-6 {
      margin-left: calc(50% - 9px + 12px); }
    .row > .col-desktop-push-7 {
      margin-left: calc(58.33333% - 9.5px + 12px); }
    .row > .col-desktop-push-8 {
      margin-left: calc(66.66667% - 10px + 12px); }
    .row > .col-desktop-push-9 {
      margin-left: calc(75% - 10.5px + 12px); }
    .row > .col-desktop-push-10 {
      margin-left: calc(83.33333% - 11px + 12px); }
    .row > .col-desktop-push-11 {
      margin-left: calc(91.66667% - 11.5px + 12px); }
    .row > .col-desktop-push-12 {
      margin-left: calc(100% - 12px + 12px); } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-1 {
      order: 1; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-1 {
      order: 1; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-1 {
      order: 1; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-2 {
      order: 2; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-2 {
      order: 2; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-2 {
      order: 2; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-3 {
      order: 3; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-3 {
      order: 3; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-3 {
      order: 3; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-4 {
      order: 4; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-4 {
      order: 4; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-4 {
      order: 4; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-5 {
      order: 5; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-5 {
      order: 5; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-5 {
      order: 5; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-6 {
      order: 6; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-6 {
      order: 6; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-6 {
      order: 6; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-7 {
      order: 7; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-7 {
      order: 7; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-7 {
      order: 7; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-8 {
      order: 8; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-8 {
      order: 8; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-8 {
      order: 8; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-9 {
      order: 9; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-9 {
      order: 9; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-9 {
      order: 9; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-10 {
      order: 10; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-10 {
      order: 10; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-10 {
      order: 10; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-11 {
      order: 11; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-11 {
      order: 11; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-11 {
      order: 11; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .row > .col-mobile-order-12 {
      order: 12; } }
  @media (max-width: 767px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-12 {
      order: 12; } }
  @media (max-width: 767px) and (min-width: 1280px) {
    .row > .col-desktop-order-12 {
      order: 12; } }

@media (min-width: 768px) and (max-width: 1279px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
    width: initial;
    display: flex;
    flex-wrap: wrap; }
    .row::after {
      clear: both;
      content: "";
      display: block; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-1 {
      width: calc(8.33333% - 13px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-2 {
      width: calc(16.66667% - 14px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-3 {
      width: calc(25% - 15px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-4 {
      width: calc(33.33333% - 16px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-5 {
      width: calc(41.66667% - 17px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-6 {
      width: calc(50% - 18px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-7 {
      width: calc(58.33333% - 19px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-8 {
      width: calc(66.66667% - 20px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-9 {
      width: calc(75% - 21px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-10 {
      width: calc(83.33333% - 22px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-11 {
      width: calc(91.66667% - 23px);
      float: left;
      margin-left: 12px; }
    .row > .col-mobile-12 {
      width: calc(100% - 24px);
      float: left;
      margin-left: 12px; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-1 {
      width: calc(8.33333% - 13px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-2 {
      width: calc(16.66667% - 14px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-3 {
      width: calc(25% - 15px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-4 {
      width: calc(33.33333% - 16px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-5 {
      width: calc(41.66667% - 17px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-6 {
      width: calc(50% - 18px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-7 {
      width: calc(58.33333% - 19px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-8 {
      width: calc(66.66667% - 20px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-9 {
      width: calc(75% - 21px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-10 {
      width: calc(83.33333% - 22px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-11 {
      width: calc(91.66667% - 23px);
      float: left;
      margin-left: 12px; }
    .row > .col-tablet-12 {
      width: calc(100% - 24px);
      float: left;
      margin-left: 12px; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-1 {
      width: calc(8.33333% - 13px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-2 {
      width: calc(16.66667% - 14px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-3 {
      width: calc(25% - 15px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-4 {
      width: calc(33.33333% - 16px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-5 {
      width: calc(41.66667% - 17px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-6 {
      width: calc(50% - 18px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-7 {
      width: calc(58.33333% - 19px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-8 {
      width: calc(66.66667% - 20px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-9 {
      width: calc(75% - 21px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-10 {
      width: calc(83.33333% - 22px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-11 {
      width: calc(91.66667% - 23px);
      float: left;
      margin-left: 12px; }
    .row > .col-desktop-12 {
      width: calc(100% - 24px);
      float: left;
      margin-left: 12px; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-push-1 {
      margin-left: calc(8.33333% - 13px + 24px); }
    .row > .col-mobile-push-2 {
      margin-left: calc(16.66667% - 14px + 24px); }
    .row > .col-mobile-push-3 {
      margin-left: calc(25% - 15px + 24px); }
    .row > .col-mobile-push-4 {
      margin-left: calc(33.33333% - 16px + 24px); }
    .row > .col-mobile-push-5 {
      margin-left: calc(41.66667% - 17px + 24px); }
    .row > .col-mobile-push-6 {
      margin-left: calc(50% - 18px + 24px); }
    .row > .col-mobile-push-7 {
      margin-left: calc(58.33333% - 19px + 24px); }
    .row > .col-mobile-push-8 {
      margin-left: calc(66.66667% - 20px + 24px); }
    .row > .col-mobile-push-9 {
      margin-left: calc(75% - 21px + 24px); }
    .row > .col-mobile-push-10 {
      margin-left: calc(83.33333% - 22px + 24px); }
    .row > .col-mobile-push-11 {
      margin-left: calc(91.66667% - 23px + 24px); }
    .row > .col-mobile-push-12 {
      margin-left: calc(100% - 24px + 24px); } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-push-1 {
      margin-left: calc(8.33333% - 13px + 24px); }
    .row > .col-tablet-push-2 {
      margin-left: calc(16.66667% - 14px + 24px); }
    .row > .col-tablet-push-3 {
      margin-left: calc(25% - 15px + 24px); }
    .row > .col-tablet-push-4 {
      margin-left: calc(33.33333% - 16px + 24px); }
    .row > .col-tablet-push-5 {
      margin-left: calc(41.66667% - 17px + 24px); }
    .row > .col-tablet-push-6 {
      margin-left: calc(50% - 18px + 24px); }
    .row > .col-tablet-push-7 {
      margin-left: calc(58.33333% - 19px + 24px); }
    .row > .col-tablet-push-8 {
      margin-left: calc(66.66667% - 20px + 24px); }
    .row > .col-tablet-push-9 {
      margin-left: calc(75% - 21px + 24px); }
    .row > .col-tablet-push-10 {
      margin-left: calc(83.33333% - 22px + 24px); }
    .row > .col-tablet-push-11 {
      margin-left: calc(91.66667% - 23px + 24px); }
    .row > .col-tablet-push-12 {
      margin-left: calc(100% - 24px + 24px); } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-push-1 {
      margin-left: calc(8.33333% - 13px + 24px); }
    .row > .col-desktop-push-2 {
      margin-left: calc(16.66667% - 14px + 24px); }
    .row > .col-desktop-push-3 {
      margin-left: calc(25% - 15px + 24px); }
    .row > .col-desktop-push-4 {
      margin-left: calc(33.33333% - 16px + 24px); }
    .row > .col-desktop-push-5 {
      margin-left: calc(41.66667% - 17px + 24px); }
    .row > .col-desktop-push-6 {
      margin-left: calc(50% - 18px + 24px); }
    .row > .col-desktop-push-7 {
      margin-left: calc(58.33333% - 19px + 24px); }
    .row > .col-desktop-push-8 {
      margin-left: calc(66.66667% - 20px + 24px); }
    .row > .col-desktop-push-9 {
      margin-left: calc(75% - 21px + 24px); }
    .row > .col-desktop-push-10 {
      margin-left: calc(83.33333% - 22px + 24px); }
    .row > .col-desktop-push-11 {
      margin-left: calc(91.66667% - 23px + 24px); }
    .row > .col-desktop-push-12 {
      margin-left: calc(100% - 24px + 24px); } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-1 {
      order: 1; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-1 {
      order: 1; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-1 {
      order: 1; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-2 {
      order: 2; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-2 {
      order: 2; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-2 {
      order: 2; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-3 {
      order: 3; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-3 {
      order: 3; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-3 {
      order: 3; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-4 {
      order: 4; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-4 {
      order: 4; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-4 {
      order: 4; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-5 {
      order: 5; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-5 {
      order: 5; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-5 {
      order: 5; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-6 {
      order: 6; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-6 {
      order: 6; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-6 {
      order: 6; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-7 {
      order: 7; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-7 {
      order: 7; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-7 {
      order: 7; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-8 {
      order: 8; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-8 {
      order: 8; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-8 {
      order: 8; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-9 {
      order: 9; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-9 {
      order: 9; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-9 {
      order: 9; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-10 {
      order: 10; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-10 {
      order: 10; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-10 {
      order: 10; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-11 {
      order: 11; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-11 {
      order: 11; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-11 {
      order: 11; } }
  @media (min-width: 768px) and (max-width: 1279px) and (max-width: 767px) {
    .row > .col-mobile-order-12 {
      order: 12; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-12 {
      order: 12; } }
  @media (min-width: 768px) and (max-width: 1279px) and (min-width: 1280px) {
    .row > .col-desktop-order-12 {
      order: 12; } }

@media (min-width: 1280px) {
  .row {
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);
    width: initial;
    display: flex;
    flex-wrap: wrap; }
    .row::after {
      clear: both;
      content: "";
      display: block; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-1 {
      width: calc(8.33333% - 26px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-2 {
      width: calc(16.66667% - 28px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-3 {
      width: calc(25% - 30px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-4 {
      width: calc(33.33333% - 32px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-5 {
      width: calc(41.66667% - 34px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-6 {
      width: calc(50% - 36px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-7 {
      width: calc(58.33333% - 38px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-8 {
      width: calc(66.66667% - 40px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-9 {
      width: calc(75% - 42px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-10 {
      width: calc(83.33333% - 44px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-11 {
      width: calc(91.66667% - 46px);
      float: left;
      margin-left: 24px; }
    .row > .col-mobile-12 {
      width: calc(100% - 48px);
      float: left;
      margin-left: 24px; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-1 {
      width: calc(8.33333% - 26px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-2 {
      width: calc(16.66667% - 28px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-3 {
      width: calc(25% - 30px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-4 {
      width: calc(33.33333% - 32px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-5 {
      width: calc(41.66667% - 34px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-6 {
      width: calc(50% - 36px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-7 {
      width: calc(58.33333% - 38px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-8 {
      width: calc(66.66667% - 40px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-9 {
      width: calc(75% - 42px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-10 {
      width: calc(83.33333% - 44px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-11 {
      width: calc(91.66667% - 46px);
      float: left;
      margin-left: 24px; }
    .row > .col-tablet-12 {
      width: calc(100% - 48px);
      float: left;
      margin-left: 24px; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-1 {
      width: calc(8.33333% - 26px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-2 {
      width: calc(16.66667% - 28px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-3 {
      width: calc(25% - 30px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-4 {
      width: calc(33.33333% - 32px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-5 {
      width: calc(41.66667% - 34px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-6 {
      width: calc(50% - 36px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-7 {
      width: calc(58.33333% - 38px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-8 {
      width: calc(66.66667% - 40px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-9 {
      width: calc(75% - 42px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-10 {
      width: calc(83.33333% - 44px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-11 {
      width: calc(91.66667% - 46px);
      float: left;
      margin-left: 24px; }
    .row > .col-desktop-12 {
      width: calc(100% - 48px);
      float: left;
      margin-left: 24px; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-push-1 {
      margin-left: calc(8.33333% - 26px + 48px); }
    .row > .col-mobile-push-2 {
      margin-left: calc(16.66667% - 28px + 48px); }
    .row > .col-mobile-push-3 {
      margin-left: calc(25% - 30px + 48px); }
    .row > .col-mobile-push-4 {
      margin-left: calc(33.33333% - 32px + 48px); }
    .row > .col-mobile-push-5 {
      margin-left: calc(41.66667% - 34px + 48px); }
    .row > .col-mobile-push-6 {
      margin-left: calc(50% - 36px + 48px); }
    .row > .col-mobile-push-7 {
      margin-left: calc(58.33333% - 38px + 48px); }
    .row > .col-mobile-push-8 {
      margin-left: calc(66.66667% - 40px + 48px); }
    .row > .col-mobile-push-9 {
      margin-left: calc(75% - 42px + 48px); }
    .row > .col-mobile-push-10 {
      margin-left: calc(83.33333% - 44px + 48px); }
    .row > .col-mobile-push-11 {
      margin-left: calc(91.66667% - 46px + 48px); }
    .row > .col-mobile-push-12 {
      margin-left: calc(100% - 48px + 48px); } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-push-1 {
      margin-left: calc(8.33333% - 26px + 48px); }
    .row > .col-tablet-push-2 {
      margin-left: calc(16.66667% - 28px + 48px); }
    .row > .col-tablet-push-3 {
      margin-left: calc(25% - 30px + 48px); }
    .row > .col-tablet-push-4 {
      margin-left: calc(33.33333% - 32px + 48px); }
    .row > .col-tablet-push-5 {
      margin-left: calc(41.66667% - 34px + 48px); }
    .row > .col-tablet-push-6 {
      margin-left: calc(50% - 36px + 48px); }
    .row > .col-tablet-push-7 {
      margin-left: calc(58.33333% - 38px + 48px); }
    .row > .col-tablet-push-8 {
      margin-left: calc(66.66667% - 40px + 48px); }
    .row > .col-tablet-push-9 {
      margin-left: calc(75% - 42px + 48px); }
    .row > .col-tablet-push-10 {
      margin-left: calc(83.33333% - 44px + 48px); }
    .row > .col-tablet-push-11 {
      margin-left: calc(91.66667% - 46px + 48px); }
    .row > .col-tablet-push-12 {
      margin-left: calc(100% - 48px + 48px); } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-push-1 {
      margin-left: calc(8.33333% - 26px + 48px); }
    .row > .col-desktop-push-2 {
      margin-left: calc(16.66667% - 28px + 48px); }
    .row > .col-desktop-push-3 {
      margin-left: calc(25% - 30px + 48px); }
    .row > .col-desktop-push-4 {
      margin-left: calc(33.33333% - 32px + 48px); }
    .row > .col-desktop-push-5 {
      margin-left: calc(41.66667% - 34px + 48px); }
    .row > .col-desktop-push-6 {
      margin-left: calc(50% - 36px + 48px); }
    .row > .col-desktop-push-7 {
      margin-left: calc(58.33333% - 38px + 48px); }
    .row > .col-desktop-push-8 {
      margin-left: calc(66.66667% - 40px + 48px); }
    .row > .col-desktop-push-9 {
      margin-left: calc(75% - 42px + 48px); }
    .row > .col-desktop-push-10 {
      margin-left: calc(83.33333% - 44px + 48px); }
    .row > .col-desktop-push-11 {
      margin-left: calc(91.66667% - 46px + 48px); }
    .row > .col-desktop-push-12 {
      margin-left: calc(100% - 48px + 48px); } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-1 {
      order: 1; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-1 {
      order: 1; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-1 {
      order: 1; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-2 {
      order: 2; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-2 {
      order: 2; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-2 {
      order: 2; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-3 {
      order: 3; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-3 {
      order: 3; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-3 {
      order: 3; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-4 {
      order: 4; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-4 {
      order: 4; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-4 {
      order: 4; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-5 {
      order: 5; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-5 {
      order: 5; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-5 {
      order: 5; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-6 {
      order: 6; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-6 {
      order: 6; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-6 {
      order: 6; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-7 {
      order: 7; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-7 {
      order: 7; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-7 {
      order: 7; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-8 {
      order: 8; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-8 {
      order: 8; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-8 {
      order: 8; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-9 {
      order: 9; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-9 {
      order: 9; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-9 {
      order: 9; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-10 {
      order: 10; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-10 {
      order: 10; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-10 {
      order: 10; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-11 {
      order: 11; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-11 {
      order: 11; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-11 {
      order: 11; } }
  @media (min-width: 1280px) and (max-width: 767px) {
    .row > .col-mobile-order-12 {
      order: 12; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 1279px) {
    .row > .col-tablet-order-12 {
      order: 12; } }
  @media (min-width: 1280px) and (min-width: 1280px) {
    .row > .col-desktop-order-12 {
      order: 12; } }

.row--no_gutters {
  margin-left: 0px;
  margin-right: 0px;
  width: calc(100% + 0px);
  width: initial;
  display: flex;
  flex-wrap: wrap; }
  .row--no_gutters::after {
    clear: both;
    content: "";
    display: block; }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-1 {
      width: calc(8.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-2 {
      width: calc(16.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-3 {
      width: calc(25%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-4 {
      width: calc(33.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-5 {
      width: calc(41.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-6 {
      width: calc(50%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-7 {
      width: calc(58.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-8 {
      width: calc(66.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-9 {
      width: calc(75%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-10 {
      width: calc(83.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-11 {
      width: calc(91.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-mobile-12 {
      width: calc(100%);
      float: left;
      margin-left: 0px; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-1 {
      width: calc(8.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-2 {
      width: calc(16.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-3 {
      width: calc(25%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-4 {
      width: calc(33.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-5 {
      width: calc(41.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-6 {
      width: calc(50%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-7 {
      width: calc(58.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-8 {
      width: calc(66.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-9 {
      width: calc(75%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-10 {
      width: calc(83.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-11 {
      width: calc(91.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-tablet-12 {
      width: calc(100%);
      float: left;
      margin-left: 0px; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-1 {
      width: calc(8.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-2 {
      width: calc(16.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-3 {
      width: calc(25%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-4 {
      width: calc(33.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-5 {
      width: calc(41.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-6 {
      width: calc(50%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-7 {
      width: calc(58.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-8 {
      width: calc(66.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-9 {
      width: calc(75%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-10 {
      width: calc(83.33333%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-11 {
      width: calc(91.66667%);
      float: left;
      margin-left: 0px; }
    .row--no_gutters > .col-desktop-12 {
      width: calc(100%);
      float: left;
      margin-left: 0px; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-push-1 {
      margin-left: calc(8.33333% + 0px); }
    .row--no_gutters > .col-mobile-push-2 {
      margin-left: calc(16.66667% + 0px); }
    .row--no_gutters > .col-mobile-push-3 {
      margin-left: calc(25% + 0px); }
    .row--no_gutters > .col-mobile-push-4 {
      margin-left: calc(33.33333% + 0px); }
    .row--no_gutters > .col-mobile-push-5 {
      margin-left: calc(41.66667% + 0px); }
    .row--no_gutters > .col-mobile-push-6 {
      margin-left: calc(50% + 0px); }
    .row--no_gutters > .col-mobile-push-7 {
      margin-left: calc(58.33333% + 0px); }
    .row--no_gutters > .col-mobile-push-8 {
      margin-left: calc(66.66667% + 0px); }
    .row--no_gutters > .col-mobile-push-9 {
      margin-left: calc(75% + 0px); }
    .row--no_gutters > .col-mobile-push-10 {
      margin-left: calc(83.33333% + 0px); }
    .row--no_gutters > .col-mobile-push-11 {
      margin-left: calc(91.66667% + 0px); }
    .row--no_gutters > .col-mobile-push-12 {
      margin-left: calc(100% + 0px); } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-push-1 {
      margin-left: calc(8.33333% + 0px); }
    .row--no_gutters > .col-tablet-push-2 {
      margin-left: calc(16.66667% + 0px); }
    .row--no_gutters > .col-tablet-push-3 {
      margin-left: calc(25% + 0px); }
    .row--no_gutters > .col-tablet-push-4 {
      margin-left: calc(33.33333% + 0px); }
    .row--no_gutters > .col-tablet-push-5 {
      margin-left: calc(41.66667% + 0px); }
    .row--no_gutters > .col-tablet-push-6 {
      margin-left: calc(50% + 0px); }
    .row--no_gutters > .col-tablet-push-7 {
      margin-left: calc(58.33333% + 0px); }
    .row--no_gutters > .col-tablet-push-8 {
      margin-left: calc(66.66667% + 0px); }
    .row--no_gutters > .col-tablet-push-9 {
      margin-left: calc(75% + 0px); }
    .row--no_gutters > .col-tablet-push-10 {
      margin-left: calc(83.33333% + 0px); }
    .row--no_gutters > .col-tablet-push-11 {
      margin-left: calc(91.66667% + 0px); }
    .row--no_gutters > .col-tablet-push-12 {
      margin-left: calc(100% + 0px); } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-push-1 {
      margin-left: calc(8.33333% + 0px); }
    .row--no_gutters > .col-desktop-push-2 {
      margin-left: calc(16.66667% + 0px); }
    .row--no_gutters > .col-desktop-push-3 {
      margin-left: calc(25% + 0px); }
    .row--no_gutters > .col-desktop-push-4 {
      margin-left: calc(33.33333% + 0px); }
    .row--no_gutters > .col-desktop-push-5 {
      margin-left: calc(41.66667% + 0px); }
    .row--no_gutters > .col-desktop-push-6 {
      margin-left: calc(50% + 0px); }
    .row--no_gutters > .col-desktop-push-7 {
      margin-left: calc(58.33333% + 0px); }
    .row--no_gutters > .col-desktop-push-8 {
      margin-left: calc(66.66667% + 0px); }
    .row--no_gutters > .col-desktop-push-9 {
      margin-left: calc(75% + 0px); }
    .row--no_gutters > .col-desktop-push-10 {
      margin-left: calc(83.33333% + 0px); }
    .row--no_gutters > .col-desktop-push-11 {
      margin-left: calc(91.66667% + 0px); }
    .row--no_gutters > .col-desktop-push-12 {
      margin-left: calc(100% + 0px); } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-1 {
      order: 1; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-1 {
      order: 1; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-1 {
      order: 1; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-2 {
      order: 2; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-2 {
      order: 2; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-2 {
      order: 2; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-3 {
      order: 3; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-3 {
      order: 3; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-3 {
      order: 3; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-4 {
      order: 4; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-4 {
      order: 4; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-4 {
      order: 4; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-5 {
      order: 5; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-5 {
      order: 5; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-5 {
      order: 5; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-6 {
      order: 6; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-6 {
      order: 6; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-6 {
      order: 6; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-7 {
      order: 7; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-7 {
      order: 7; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-7 {
      order: 7; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-8 {
      order: 8; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-8 {
      order: 8; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-8 {
      order: 8; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-9 {
      order: 9; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-9 {
      order: 9; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-9 {
      order: 9; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-10 {
      order: 10; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-10 {
      order: 10; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-10 {
      order: 10; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-11 {
      order: 11; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-11 {
      order: 11; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-11 {
      order: 11; } }
  @media (max-width: 767px) {
    .row--no_gutters > .col-mobile-order-12 {
      order: 12; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .row--no_gutters > .col-tablet-order-12 {
      order: 12; } }
  @media (min-width: 1280px) {
    .row--no_gutters > .col-desktop-order-12 {
      order: 12; } }

.section__background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; }
  .section__background img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.article {
  position: relative;
  padding: 42px 188px;
  font-family: Roboto;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  color: #545454; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .article {
      padding: 42px 10px;
      font-size: 26px;
      line-height: 32px; } }
  .article p {
    font-size: 1em;
    line-height: 1.6em;
    margin-bottom: 22px; }
  .article h2 {
    color: #d09240;
    font-weight: bold;
    font-size: 1.6em;
    line-height: 1.8em;
    margin-bottom: 22px; }
    .article h2.headline {
      font-size: 2em;
      line-height: 2.2em; }
  .article h3 {
    font-size: 1.4em;
    line-height: 1.6em;
    color: #d09240;
    font-weight: bold;
    margin-bottom: 22px; }
  .article h4 {
    font-size: 1.4em;
    line-height: 1.6em;
    color: #d09240;
    font-weight: bold;
    margin-bottom: 22px; }
  .article strong {
    font-weight: bold; }
  .article a {
    color: #d09240;
    font-style: italic;
    text-decoration: none; }
  .article ul {
    margin-bottom: 22px; }
    .article ul > li {
      position: relative;
      padding-left: 20px;
      font-size: 1em;
      line-height: 1.6em; }
      .article ul > li:before {
        content: "";
        display: block;
        position: absolute;
        left: 6px;
        background: #545454;
        width: 6px;
        height: 1px;
        top: 16px; }
  .article img {
    max-width: 100%;
    display: block;
    margin: 0 auto 22px; }
  .article__list__dash > li:before {
    background: #545454;
    width: 6px;
    height: 1px;
    top: 16px; }
  .article__list__dot > li:before {
    background: #545454;
    width: 8px;
    height: 8px;
    border-radius: 99px;
    top: 16px; }
  .article__list__check > li:before {
    width: 8px;
    height: 16px;
    background: transparent;
    border-width: 3px;
    border-style: solid;
    border-color: transparent #545454 #545454 transparent;
    transform: rotate(45deg);
    top: 8px; }
  .article--detail {
    font-size: 20px;
    line-height: 28px;
    color: #282828;
    text-align: center; }
    @media (max-width: 767px) {
      .article--detail {
        font-size: 15px;
        line-height: 24px;
        text-align: left; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      .article--detail {
        font-size: 15px;
        line-height: 24px;
        text-align: left; } }
    .article--detail h2 {
      font-size: 28px;
      line-height: 34px;
      color: #282828; }
      @media (max-width: 767px) {
        .article--detail h2 {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          text-align: left; } }
      @media (min-width: 768px) and (max-width: 1279px) {
        .article--detail h2 {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          text-align: left; } }
    .article--detail h3 {
      font-size: 22px;
      line-height: 26px;
      color: #FFFFFF;
      padding: 20px 20px; }
      @media (max-width: 767px) {
        .article--detail h3 {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          text-align: left; } }
      @media (min-width: 768px) and (max-width: 1279px) {
        .article--detail h3 {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          text-align: left; } }
  .article table {
    margin: 0 auto 22px;
    border-collapse: collapse; }
    .article table tr:nth-child(odd),
    .article table tr.odd,
    .article table tr.OddRow {
      background: #fff; }
    .article table tr:nth-child(even),
    .article table tr.even,
    .article table tr.EvenRow {
      background: #e9f1f8; }
    .article table td, .article table th {
      border: 1px solid #25358b;
      padding: 3px 10px; }
    .article table th {
      color: #fff;
      font-weight: bolder;
      padding: 5px 10px;
      background: #25358b;
      text-align: center; }

.navigation {
  width: 100%;
  pointer-events: all;
  position: absolute;
  z-index: 5;
  height: 84px;
  transition: height 0.2s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .navigation__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75); }
    .navigation__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .navigation__content {
    position: relative; }
  .navigation nav:not(.compact) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative; }
    .navigation nav:not(.compact) ul {
      display: flex;
      align-items: center;
      justify-content: center; }
      .navigation nav:not(.compact) ul li {
        position: relative;
        margin: 0 4px; }
        @media (max-width: 767px) {
          .navigation nav:not(.compact) ul li {
            margin: 0 2px; } }
        @media (min-width: 768px) and (max-width: 1279px) {
          .navigation nav:not(.compact) ul li {
            margin: 0 2px; } }
        .navigation nav:not(.compact) ul li a:hover + ul, .navigation nav:not(.compact) ul li a.active + ul, .navigation nav:not(.compact) ul li a:focus + ul {
          transform: scale(1, 1);
          max-height: 9999px; }
        .navigation nav:not(.compact) ul li ul {
          display: flex;
          align-items: center;
          z-index: 6;
          position: absolute;
          top: 100%;
          left: calc(50% - 240px / 2);
          width: 240px;
          background: white;
          flex-wrap: wrap;
          transform-origin: top center;
          transform: scale(0.8, 0);
          transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); }
          @media (max-width: 767px) {
            .navigation nav:not(.compact) ul li ul {
              transform: scale(0, 0.8);
              transform-origin: right center;
              max-height: 0;
              overflow: hidden;
              left: auto;
              right: 100%;
              top: 0%; } }
          @media (min-width: 768px) and (max-width: 1279px) {
            .navigation nav:not(.compact) ul li ul {
              transform: scale(0, 0.8);
              transform-origin: right center;
              max-height: 0;
              overflow: hidden;
              left: auto;
              right: 100%;
              top: 0%; } }
          .navigation nav:not(.compact) ul li ul:hover {
            transform: scale(1, 1);
            max-height: 9999px; }
          .navigation nav:not(.compact) ul li ul li {
            width: 100%;
            margin: 0 0px; }
            .navigation nav:not(.compact) ul li ul li:not(:last-child) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .navigation nav:not(.compact) ul > li:not(:last-child):after {
        content: "";
        display: block;
        position: absolute;
        background-image: url(../images/_sprites-full.png);
        background-position: -1223px -198px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 43%;
        left: 99%; }
      .navigation nav:not(.compact) ul > li:not(:last-child) li:after {
        display: none; }
  .navigation nav.compact {
    display: none;
    height: 84px; }
  @media (max-width: 767px) {
    .navigation nav.compact {
      display: block; }
      .navigation nav.compact ul {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .navigation nav.compact ul li:first-child {
          margin-right: auto; }
        .navigation nav.compact ul li:not(:first-child) {
          margin: 0 4px; }
    .navigation nav:not(.compact) {
      flex-wrap: wrap;
      width: 300px;
      margin-left: auto;
      background: #FFF;
      box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.4);
      transform: scale(0.8, 0);
      transition: transform .2s;
      transform-origin: top right;
      height: 0; }
      .navigation nav:not(.compact).active {
        transform: scale(1, 1);
        height: 100%; }
      .navigation nav:not(.compact) ul {
        flex-wrap: wrap;
        width: 100%; }
        .navigation nav:not(.compact) ul li {
          width: 100%;
          border-bottom: 1px solid #CCC; }
          .navigation nav:not(.compact) ul li ul {
            box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.4); }
        .navigation nav:not(.compact) ul > li:not(:last-child):after {
          display: none; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .navigation nav.compact {
      display: block; }
      .navigation nav.compact ul {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .navigation nav.compact ul li:first-child {
          margin-right: auto; }
        .navigation nav.compact ul li:not(:first-child) {
          margin: 0 4px; }
    .navigation nav:not(.compact) {
      flex-wrap: wrap;
      width: 300px;
      margin-left: auto;
      background: #FFF;
      box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.4);
      transform: scale(0.8, 0);
      transition: transform .2s;
      transform-origin: top right;
      height: 0; }
      .navigation nav:not(.compact).active {
        transform: scale(1, 1);
        height: 100%; }
      .navigation nav:not(.compact) ul {
        flex-wrap: wrap;
        width: 100%; }
        .navigation nav:not(.compact) ul li {
          width: 100%;
          border-bottom: 1px solid #CCC; }
          .navigation nav:not(.compact) ul li ul {
            box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.4); }
        .navigation nav:not(.compact) ul > li:not(:last-child):after {
          display: none; } }
  .navigation--pwth nav .music .label, .navigation--pwth nav .install .label, .navigation--pwth nav .facebook .label {
    font-size: 0; }
  .navigation--pwth nav .music:hover, .navigation--pwth nav .install:hover, .navigation--pwth nav .facebook:hover {
    filter: brightness(120%); }
  .navigation--pwth nav .music {
    display: block;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px -799px;
    width: 50px;
    height: 50px; }
    .navigation--pwth nav .music.active {
      background-image: url(../images/_sprites-full.png);
      background-position: -1176px -699px;
      width: 50px;
      height: 50px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .navigation--pwth nav .music {
        justify-self: center;
        display: none; } }
  .navigation--pwth nav .appicon {
    display: block;
    width: 342px;
    height: 84px;
    background: url(../../dist/assets/images/common/appicon-mb.png);
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    justify-content: flex-end; }
    .navigation--pwth nav .appicon .label {
      font-size: 30px;
      font-weight: bold;
      color: #2e53a1; }
  .navigation--pwth nav .install {
    background-image: url(../images/_sprites-full.png);
    background-position: -342px -708px;
    width: 200px;
    height: 59px; }
  .navigation--pwth nav .facebook {
    background-image: url(../images/_sprites-full.png);
    background-position: -1176px -629px;
    width: 57px;
    height: 58px; }
  .navigation--pwth nav .hamburger {
    background-color: #1e31a2;
    padding: 16px 14px; }

.sidebar {
  width: 170px;
  height: 601px;
  pointer-events: all;
  position: absolute;
  right: 20px;
  top: 20%;
  z-index: 5;
  transform: translateX(100%);
  transition: all 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045); }
  .sidebar__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .sidebar__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .sidebar__content {
    position: relative;
    padding: 50px 10px 60px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .sidebar--pwth .ios {
    display: block;
    margin-bottom: 6px;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px -509px;
    width: 130px;
    height: 40px; }
    .sidebar--pwth .ios:hover {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -469px;
      width: 130px;
      height: 40px; }
  .sidebar--pwth .android {
    margin-bottom: 6px;
    display: block;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px -589px;
    width: 130px;
    height: 40px; }
    .sidebar--pwth .android:hover {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -389px;
      width: 130px;
      height: 40px; }
  .sidebar--pwth .apk {
    margin-bottom: 6px;
    display: block;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px -429px;
    width: 130px;
    height: 40px; }
    .sidebar--pwth .apk:hover {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -549px;
      width: 130px;
      height: 40px; }
  .sidebar--pwth .appicon {
    margin-bottom: 6px;
    display: block;
    width: 130px;
    height: 130px;
    background: url(../../dist/assets/images/common/appicon-side.png); }
  .sidebar--pwth .normal {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    display: block;
    font-size: 24px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    text-decoration: none;
    height: 34px; }
    .sidebar--pwth .normal:hover {
      background: #324385; }
  .sidebar--pwth .topup {
    display: block;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px 0px;
    width: 138px;
    height: 90px; }
    .sidebar--pwth .topup:hover {
      filter: brightness(110%); }
  .sidebar--pwth .split {
    display: block;
    margin-bottom: 6px;
    background-image: url(../images/_sprites-full.png);
    background-position: -1105px -849px;
    width: 90px;
    height: 1px; }
  .sidebar--pwth .hamburger {
    background-color: #1e31a2;
    padding: 16px 14px; }
  .sidebar__toggle {
    background-color: transparent;
    border: none;
    background-image: url(../images/_sprites-full.png);
    background-position: -1164px -90px;
    width: 59px;
    height: 194px;
    position: absolute;
    font-size: 0px;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% - 2px); }
    .sidebar__toggle:hover {
      cursor: pointer;
      filter: brightness(120%); }
    .sidebar__toggle:focus {
      outline: none; }
  .sidebar.active {
    transform: translateX(0%); }
    .sidebar.active .sidebar__toggle {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -90px;
      width: 59px;
      height: 194px; }

.header {
  position: relative;
  width: 100%;
  height: 1252px;
  background: #e2d9c5;
  pointer-events: none; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .header {
      height: 1200px;
      padding-top: 0px; } }
  @media (max-width: 1023px) and (orientation: portrait) {
    .header--sub {
      height: 160px; } }
  .header--norank {
    margin-bottom: 300px; }
  .header__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .header__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .header__background img.desktop {
          display: none; } }
      .header__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .header__background img.mobile {
            display: block; } }
    .header__background video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .header__content {
    position: relative;
    height: 100%;
    pointer-events: all; }
    .header__content .trailer {
      display: block;
      pointer-events: all;
      position: absolute;
      top: 380px;
      left: calc(50% + 540px);
      background-image: url(../images/_sprites-full.png);
      background-position: -750px -440px;
      width: 132px;
      height: 134px; }
      .header__content .trailer:hover {
        filter: brightness(120%); }
      @media (max-width: 1023px) and (orientation: portrait) {
        .header__content .trailer {
          display: none; } }

.general {
  position: absolute;
  pointer-events: none;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%) translateY(-600px);
  z-index: 3; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .general {
      width: 100%;
      position: relative;
      transform: translateX(-50%) translateY(-230px); } }
  .general--norank {
    transform: translateX(-50%) translateY(-900px); }
    @media (max-width: 1023px) and (orientation: portrait) {
      .general--norank {
        transform: translateX(-50%) translateY(-530px); } }
  .general__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .general__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .general__background img.desktop {
          display: none; } }
      .general__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .general__background img.mobile {
            display: block; } }
  .general__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    pointer-events: all; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .general__content {
        flex-wrap: wrap; } }
    .general__content .appinfo {
      width: 382px;
      pointer-events: all; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .general__content .appinfo {
          width: 100%;
          height: 230px;
          display: flex;
          justify-content: center; } }
      .general__content .appinfo__cta {
        margin-bottom: 10px; }
        .general__content .appinfo__cta .topup {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: 0px -304px;
          width: 376px;
          height: 284px; }
          .general__content .appinfo__cta .topup:hover {
            filter: brightness(120%); }
        @media (max-width: 1023px) and (orientation: portrait) {
          .general__content .appinfo__cta .topup {
            display: block;
            background-image: url(../images/_sprites-full.png);
            background-position: -376px -394px;
            width: 338px;
            height: 90px;
            margin: 8px auto; }
            .general__content .appinfo__cta .topup:hover {
              filter: brightness(120%); }
          .general__content .appinfo__cta .download {
            display: block;
            background-image: url(../images/_sprites-full.png);
            background-position: -376px -304px;
            width: 338px;
            height: 90px;
            margin: 8px auto; }
            .general__content .appinfo__cta .download:hover {
              filter: brightness(120%); } }
      .general__content .appinfo__download {
        width: 382px;
        height: 170px;
        margin-bottom: 20px;
        position: relative; }
        .general__content .appinfo__download .ios {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -320px;
          width: 185px;
          height: 80px;
          position: absolute;
          left: 0;
          top: 0; }
          .general__content .appinfo__download .ios:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -920px -240px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__download .android {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -720px;
          width: 185px;
          height: 80px;
          position: absolute;
          right: 0;
          top: 0; }
          .general__content .appinfo__download .android:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: 0px -858px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__download .apk {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -160px;
          width: 185px;
          height: 80px;
          position: absolute;
          left: 0;
          bottom: 0; }
          .general__content .appinfo__download .apk:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -555px -778px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__download .nox {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -480px;
          width: 185px;
          height: 80px;
          position: absolute;
          right: 0;
          bottom: 0; }
          .general__content .appinfo__download .nox:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -920px -640px;
            width: 185px;
            height: 80px; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .general__content .appinfo__download {
            display: none; } }
      .general__content .appinfo__features {
        width: 382px;
        height: 170px;
        position: relative;
        margin-bottom: 20px; }
        .general__content .appinfo__features .char {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -185px -778px;
          width: 185px;
          height: 80px;
          position: absolute;
          left: 0;
          top: 0; }
          .general__content .appinfo__features .char:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: 0px -778px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__features .benefit {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -560px;
          width: 185px;
          height: 80px;
          position: absolute;
          right: 0;
          top: 0; }
          .general__content .appinfo__features .benefit:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -920px -400px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__features .clan {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -185px -858px;
          width: 185px;
          height: 80px;
          position: absolute;
          left: 0;
          bottom: 0; }
          .general__content .appinfo__features .clan:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -370px -778px;
            width: 185px;
            height: 80px; }
        .general__content .appinfo__features .pk {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -920px -80px;
          width: 185px;
          height: 80px;
          position: absolute;
          right: 0;
          bottom: 0; }
          .general__content .appinfo__features .pk:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -920px 0px;
            width: 185px;
            height: 80px; }
      .general__content .appinfo__code {
        width: 380px;
        height: 120px;
        position: relative; }
        .general__content .appinfo__code .code {
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: 0px -588px;
          width: 380px;
          height: 120px;
          position: absolute;
          left: 0;
          top: 0; }
          .general__content .appinfo__code .code:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -380px -588px;
            width: 380px;
            height: 120px; }
    .general__content .information {
      margin-top: 154px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .general__content .information {
          background: #daedfe;
          width: 100%;
          margin-top: initial; } }
      .general__content .information .border_news {
        margin-bottom: 20px; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .general__content .information .border_news {
            margin: 0 auto; } }
      .general__content .information .events {
        display: block;
        width: 780px;
        height: 310px;
        max-width: 100%;
        background: url(../../dist/assets/images/bg/list-events.jpg); }
        @media (max-width: 1023px) and (orientation: portrait) {
          .general__content .information .events {
            width: 690px;
            height: 310px;
            margin: 0 auto; } }

.rank {
  position: relative;
  height: 1643px;
  background: transparent;
  pointer-events: none;
  margin-top: -170px; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .rank {
      height: 1200px;
      padding-top: 0px;
      margin-top: -230px; } }
  .rank__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .rank__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .rank__background img.desktop {
          display: none; } }
      .rank__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .rank__background img.mobile {
            display: block; } }
  .rank__content {
    position: relative;
    height: 100%;
    pointer-events: all; }
    .rank__content:before {
      content: "";
      display: block;
      height: 365px;
      width: 100%; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .rank__content:before {
          display: none; } }
    .rank__content .rank__server {
      display: flex;
      width: 100%;
      justify-content: center; }
      .rank__content .rank__server .form-control {
        margin: 0 30px; }
        .rank__content .rank__server .form-control select + .select2 .select2-selection {
          border-radius: 50px;
          width: 240px;
          height: 50px;
          display: flex;
          align-items: center;
          border: 2px solid #b8e2fd; }
          .rank__content .rank__server .form-control select + .select2 .select2-selection__rendered {
            color: #1e3f51;
            font-size: 20px;
            line-height: 26px;
            padding: 0 20px; }
          .rank__content .rank__server .form-control select + .select2 .select2-selection__arrow {
            background-image: url(../images/_sprites-full.png);
            background-position: -1184px -799px;
            width: 35px;
            height: 21px;
            top: calc(50% - 20px / 2); }
        .rank__content .rank__server .form-control select + .select2.select2-container--open .selection .select2-selection__arrow {
          transform: rotate(180deg); }
        .rank__content .rank__server .form-control select + .select2.select2-container--focus .selection .select2-selection {
          border: 2px solid #2596E8;
          box-shadow: 0 0 0 1px #2596E8; }
    .rank__content .rank__single {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
      .rank__content .rank__single ul {
        width: 100%; }
      .rank__content .rank__single li {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        font-size: 20px;
        color: #1e3f51; }
        .rank__content .rank__single li:nth-child(1) {
          width: calc(50% / 3 * 1); }
        .rank__content .rank__single li:nth-child(2) {
          width: calc(50% / 3 * 2); }
        .rank__content .rank__single li:nth-child(3) {
          width: calc(50% / 3); }
        .rank__content .rank__single li:nth-child(4) {
          width: calc(50% / 3); }
        .rank__content .rank__single li:nth-child(5) {
          width: calc(50% / 3); }
      .rank__content .rank__single:not(:last-child):after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #c7dcf9;
        background: radial-gradient(circle, #c7dcf9 50%, rgba(199, 220, 249, 0) 100%); }
      .rank__content .rank__single--top1, .rank__content .rank__single--top2, .rank__content .rank__single--top3 {
        font-weight: 600; }
        .rank__content .rank__single--top1 li:first-child .icon, .rank__content .rank__single--top2 li:first-child .icon, .rank__content .rank__single--top3 li:first-child .icon {
          margin-left: 10px; }
      .rank__content .rank__single--top1 li:first-child {
        color: #fc2126; }
        .rank__content .rank__single--top1 li:first-child .icon > svg > path {
          fill: #fc2126; }
      .rank__content .rank__single--top2 li:first-child {
        color: #ef8508; }
        .rank__content .rank__single--top2 li:first-child .icon > svg > path {
          fill: #ef8508; }
      .rank__content .rank__single--top3 li:first-child {
        color: #ef8508; }
        .rank__content .rank__single--top3 li:first-child .icon > svg > path {
          fill: #ef8508; }
    .rank__content .rank__label .rank__single li {
      font-size: 28px;
      color: #3355ab; }
    .rank__content .rank__page {
      display: flex;
      width: 100%;
      justify-content: center; }
      .rank__content .rank__page ul {
        display: flex;
        justify-self: center; }
        .rank__content .rank__page ul li {
          margin: 0 2px; }
          .rank__content .rank__page ul li, .rank__content .rank__page ul li .page, .rank__content .rank__page ul li .text {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 42px; }
          .rank__content .rank__page ul li .prev {
            font-size: 0;
            background-image: url(../images/_sprites-full.png);
            background-position: -1223px -90px;
            width: 16px;
            height: 27px; }
          .rank__content .rank__page ul li .next {
            font-size: 0;
            background-image: url(../images/_sprites-full.png);
            background-position: -1223px -117px;
            width: 16px;
            height: 27px; }
          .rank__content .rank__page ul li .page {
            width: 42px;
            height: 42px;
            border-radius: 20px;
            color: #3355ab;
            font-weight: bold;
            text-decoration: none; }
            .rank__content .rank__page ul li .page.active {
              background: #f1db88; }
            .rank__content .rank__page ul li .page:hover {
              position: relative;
              z-index: 2; }
              .rank__content .rank__page ul li .page:hover:before {
                content: "";
                display: block;
                width: 80%;
                height: 80%;
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                border-radius: 20px;
                border-width: 1px;
                border-style: solid;
                border-color: #f1db88;
                background: rgba(241, 219, 136, 0.2); }
          .rank__content .rank__page ul li .text {
            height: 42px;
            width: 42px; }

.select2-container .select2-dropdown {
  border: 2px solid #b8e2fd !important;
  border-radius: 12px !important;
  overflow: hidden; }

.characters {
  position: relative;
  height: 1689px;
  background: transparent;
  pointer-events: none;
  margin-top: -230px;
  z-index: 3; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .characters {
      height: calc(1200px + 130px);
      padding-top: 0px;
      margin-top: 0px; } }
  @media (max-width: 1023px) and (orientation: portrait) {
    .characters--norank {
      margin-top: -530px; } }
  .characters__background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .characters__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .characters__background img.desktop {
          display: none; } }
      .characters__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .characters__background img.mobile {
            display: block; } }
    .characters__background .circle {
      display: block;
      width: 999px;
      height: 1002px;
      position: absolute;
      top: 390px;
      left: calc(50% - 640px);
      animation: openportal 20s infinite; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .characters__background .circle {
          top: 150px;
          left: calc(50% - 140px); } }

@keyframes openportal {
  0% {
    filter: brightness(1) blur(0px);
    transform: scale(1) rotate(0deg); }
  30% {
    filter: brightness(1) blur(3px);
    transform: scale(0.95) rotate(-15deg); }
  80% {
    filter: brightness(1.2) blur(0px);
    transform: scale(1.05) rotate(180deg); }
  100% {
    filter: brightness(1) blur(0px);
    transform: scale(1) rotate(0deg); } }
    .characters__background .land {
      display: block;
      width: 1288px;
      height: 431px;
      position: absolute;
      top: 1180px;
      left: calc(50% - 820px);
      animation: openland 30s infinite; }

@keyframes openland {
  0% {
    filter: brightness(1);
    transform: scale(1); }
  30% {
    filter: brightness(1);
    transform: scale(0.95); }
  80% {
    filter: brightness(1.3);
    transform: scale(1.1); }
  100% {
    filter: brightness(1);
    transform: scale(1); } }
  .characters__content {
    position: relative;
    height: 100%; }
    .characters__content:before {
      content: "";
      display: block;
      height: 255px;
      width: 100%; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .characters__content:before {
          display: none; } }
    .characters__content .swiper-container {
      pointer-events: all; }
    .characters__content .swiper-button-prev-listChars, .characters__content .swiper-button-next-listChars {
      pointer-events: all; }
      .characters__content .swiper-button-prev-listChars:after, .characters__content .swiper-button-next-listChars:after {
        display: none; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .characters__content .swiper-button-prev-listChars, .characters__content .swiper-button-next-listChars {
          display: none; } }
    .characters__content .swiper-button-prev-listChars {
      background-image: url(../images/_sprites-full.png);
      background-position: 0px -938px;
      width: 149px;
      height: 84px;
      left: 250px; }
    .characters__content .swiper-button-next-listChars {
      background-image: url(../images/_sprites-full.png);
      background-position: 0px -938px;
      width: 149px;
      height: 84px;
      right: 250px;
      transform: scaleX(-1); }
    .characters__content .swiper-pagination-listChars {
      bottom: initial;
      left: initial;
      left: initial;
      right: initial;
      top: initial;
      bottom: initial;
      position: static;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: calc(100% - 26px); }
      .characters__content .swiper-pagination-listChars .swiper-pagination-bullet {
        opacity: 1;
        border-radius: 0;
        background: transparent;
        width: initial;
        height: initial;
        display: block;
        width: 100%;
        height: 40px; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet {
            width: 90px;
            height: 100%;
            margin: 0 12px; } }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet:not(:last-child) {
          margin-bottom: 1px; }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .thumbnail {
          display: none; }
          @media (max-width: 1023px) and (orientation: portrait) {
            .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .thumbnail {
              display: block; } }
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .thumbnail__normal {
            display: block; }
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .thumbnail__hover {
            display: none; }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label {
          position: relative;
          text-transform: capitalize;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          color: #3355ab;
          background: #ffdd8c;
          font-size: 18px; }
          @media (max-width: 1023px) and (orientation: portrait) {
            .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label {
              display: none; } }
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label:before, .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #cfae47;
            transform: rotate(45deg);
            position: absolute;
            top: 17px; }
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label:before {
            left: 6px; }
          .characters__content .swiper-pagination-listChars .swiper-pagination-bullet .label:after {
            right: 6px; }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet-active .thumbnail__normal {
          display: none; }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet-active .thumbnail__hover {
          display: block; }
        .characters__content .swiper-pagination-listChars .swiper-pagination-bullet:hover .label, .characters__content .swiper-pagination-listChars .swiper-pagination-bullet-active .label, .characters__content .swiper-pagination-listChars .swiper-pagination-bullet-active:hover .label {
          background: #fbeda1; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="barbarian"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="barbarian"] .thumbnail img {
          width: 708px;
          height: 857px; } }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="vulpine"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="vulpine"] .thumbnail img {
          width: 715px;
          height: 975px; } }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="cleric"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="cleric"] .thumbnail img {
          width: 632px;
          height: 937px; } }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="archer"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="archer"] .thumbnail img {
          width: 768px;
          height: 991px; } }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="blademaster"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="blademaster"] .thumbnail img {
          width: 644px;
          height: 1056px; } }
    @media (max-width: 1023px) and (orientation: portrait) {
      .characters__content .swiper-slide[data-label="wizard"] .thumbnail {
        bottom: -4px;
        left: auto;
        right: 0; }
        .characters__content .swiper-slide[data-label="wizard"] .thumbnail img {
          width: 728px;
          height: 975px; } }

.gallery {
  position: relative;
  height: 1678px;
  background: transparent;
  pointer-events: none;
  margin-top: -180px; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .gallery {
      height: 1888px;
      padding-top: 0px;
      margin-top: 0px; } }
  .gallery__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .gallery__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .gallery__background img.desktop {
          display: none; } }
      .gallery__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .gallery__background img.mobile {
            display: block; } }
  .gallery__content {
    position: relative;
    height: 100%; }
    .gallery__content:before {
      content: "";
      display: block;
      height: 190px;
      width: 100%; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .gallery__content:before {
          display: none; } }
    .gallery__content .swiper-container {
      pointer-events: all; }
    .gallery__content .swiper-pagination-listGallery {
      bottom: initial;
      left: initial;
      left: initial;
      right: initial;
      top: initial;
      bottom: initial;
      position: static;
      z-index: 5;
      display: flex; }
      .gallery__content .swiper-pagination-listGallery .swiper-pagination-bullet {
        opacity: 1;
        border-radius: 0;
        background: transparent;
        width: initial;
        height: initial;
        width: 16px;
        height: 16px;
        background: #3355ab;
        transform: rotate(45deg);
        margin: 0 16px; }
        .gallery__content .swiper-pagination-listGallery .swiper-pagination-bullet-active {
          background: #3868e0;
          outline: 2px solid #6e98f9; }
    .gallery__content .swiper-button-prev-listGallery, .gallery__content .swiper-button-next-listGallery {
      display: block;
      position: static;
      pointer-events: all;
      margin: 0 16px; }
      .gallery__content .swiper-button-prev-listGallery:after, .gallery__content .swiper-button-next-listGallery:after {
        display: none; }
    .gallery__content .swiper-button-prev-listGallery {
      background-image: url(../images/_sprites-full.png);
      background-position: -1223px -90px;
      width: 16px;
      height: 27px; }
    .gallery__content .swiper-button-next-listGallery {
      background-image: url(../images/_sprites-full.png);
      background-position: -1223px -117px;
      width: 16px;
      height: 27px; }
  .gallery__wrapper {
    pointer-events: all;
    position: absolute;
    top: 610px;
    left: calc(50% - 605px);
    width: 925px;
    height: 620px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .gallery__wrapper {
        width: 100%;
        left: 0;
        top: 320px; } }
  .gallery__list {
    display: block;
    position: relative;
    width: 100%;
    height: 550px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .gallery__list {
        height: 1150px; } }
  .gallery__item {
    position: absolute; }
    .gallery__item:nth-child(n+3) .border_gallery {
      width: 290px;
      height: 170px; }
    .gallery__item:nth-child(1) {
      top: 0px;
      left: calc(50% - 460px); }
    .gallery__item:nth-child(2) {
      top: 80px;
      left: calc(50% + 30px); }
    .gallery__item:nth-child(3) {
      top: 360px;
      left: calc(50% - 460px); }
    .gallery__item:nth-child(4) {
      top: 360px;
      left: calc(50% - 145px); }
    .gallery__item:nth-child(5) {
      top: 360px;
      left: calc(50% + 170px); }
    @media (max-width: 1023px) and (orientation: portrait) {
      .gallery__item {
        position: static; }
        .gallery__item .border_gallery, .gallery__item:nth-child(3) .border_gallery {
          width: 690px;
          height: 372px;
          margin-bottom: 12px; }
        .gallery__item:nth-child(n+4) {
          display: none; } }
    .gallery__item a.youtube {
      display: block;
      width: 100%;
      height: 100%;
      position: relative; }
      .gallery__item a.youtube img {
        filter: brightness(80%); }
      .gallery__item a.youtube:after {
        content: "";
        display: block;
        position: absolute;
        background-image: url(../images/_sprites-full.png);
        background-position: -750px -440px;
        width: 132px;
        height: 134px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.4);
        transform-origin: center center; }
  .gallery__page {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center; }

.subpage {
  position: relative;
  pointer-events: none; }
  .subpage__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f5f9fd; }
    .subpage__background img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom; }
  .subpage__content {
    position: relative;
    height: 100%; }
  .subpage__title {
    width: 1000px;
    height: 110px;
    max-width: 100%;
    background: url(../../dist/assets/images/bg/sub-title.jpg);
    display: flex;
    align-items: center;
    justify-content: center; }
    .subpage__title .label {
      font-size: 42px;
      font-weight: bold;
      color: #fefefe; }
  .subpage__wrapper {
    position: relative;
    width: 1000px;
    max-width: 100%;
    font-size: 18px;
    color: #5b6a82;
    left: 50%;
    transform: translateX(-50%) translateY(-400px);
    background: #fff;
    text-align: center;
    bottom: 180px;
    pointer-events: all;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .subpage__wrapper {
        padding-bottom: 220px;
        bottom: 80px;
        background: transparent;
        transform: translateX(-50%) translateY(0); } }
    .subpage__wrapper p {
      width: 100%;
      line-height: 32px; }
    .subpage__wrapper .logo_pw, .subpage__wrapper .logo_vng {
      display: inline-flex;
      margin: 0 12px 12px; }
    .subpage__wrapper .logo_vng {
      background-image: url(../images/_sprites-full.png);
      background-position: -1209px -284px;
      width: 33px;
      height: 49px; }
    .subpage__wrapper .logo_pw {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -769px;
      width: 110px;
      height: 30px; }

.footer {
  position: relative;
  height: 543px;
  pointer-events: none; }
  .footer__background {
    position: absolute;
    width: 100%;
    height: 100%; }
    .footer__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .footer__background img.desktop {
          display: none; } }
      .footer__background img.mobile {
        display: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .footer__background img.mobile {
            display: block; } }
  .footer__content {
    position: relative;
    height: 100%; }
  .footer__wrapper {
    position: absolute;
    width: 100%;
    font-size: 18px;
    color: #5b6a82;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    bottom: 180px;
    pointer-events: all;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .footer__wrapper {
        bottom: 80px; } }
    .footer__wrapper p {
      width: 100%;
      line-height: 32px; }
    .footer__wrapper .logo_pw, .footer__wrapper .logo_vng {
      display: inline-flex;
      margin: 0 12px 12px; }
    .footer__wrapper .logo_vng {
      background-image: url(../images/_sprites-full.png);
      background-position: -1209px -284px;
      width: 33px;
      height: 49px; }
    .footer__wrapper .logo_pw {
      background-image: url(../images/_sprites-full.png);
      background-position: -1105px -769px;
      width: 110px;
      height: 30px; }

.form-control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  margin-bottom: 10px; }
  .form-control .error {
    position: absolute;
    display: none;
    padding: 6px;
    background: #ff3333;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    top: 100%;
    right: 0;
    box-sizing: border-box;
    z-index: 3; }
    .form-control .error:before {
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-width: 3px;
      border-style: solid;
      border-color: transparent #ff3333 #ff3333 transparent;
      position: absolute;
      bottom: calc(100% - 3px);
      right: 10px;
      transform: rotate(225deg); }
  .form-control .error-full {
    display: none;
    padding: 16px 6px;
    background: rgba(255, 51, 51, 0.1);
    color: #cc0000;
    border: 1px solid rgba(204, 0, 0, 0.4);
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    top: 100%;
    right: 0;
    box-sizing: border-box;
    z-index: 3; }
  .form-control--error .error {
    display: inline-block; }
  .form-control--error .error-full {
    display: block; }
  .form-control .available {
    position: absolute;
    display: none;
    right: 10px;
    bottom: 8px; }
    .form-control .available .icon svg {
      width: 20px;
      height: 20px; }
  .form-control--available .available {
    display: block; }
  .form-control input[type="file"] {
    display: none; }
    .form-control input[type="file"] + label {
      display: block;
      border: 1px dashed rgba(250, 250, 250, 0.5);
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      padding: 28px 18px;
      cursor: pointer; }
      .form-control input[type="file"] + label .label {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #949494;
        opacity: 0.7;
        text-align: center; }
      .form-control input[type="file"] + label .icon {
        display: block;
        text-align: center;
        margin-bottom: 10px; }
        .form-control input[type="file"] + label .icon svg {
          height: 32px;
          width: auto; }
      .form-control input[type="file"] + label + .imageInput {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 150px;
        height: 150px;
        overflow: hidden;
        background: #242424; }
        .form-control input[type="file"] + label + .imageInput img {
          width: 150px;
          height: 150px;
          object-fit: cover; }
    .form-control input[type="file"].active + label {
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      transition: opacity .2s; }
      .form-control input[type="file"].active + label .label {
        color: #fff; }
      .form-control input[type="file"].active + label:hover {
        opacity: 1; }
  .form-control input[type="text"], .form-control input[type="password"], .form-control input[type="email"], .form-control input[type="number"] {
    width: 84%;
    order: 2;
    background: #f5ead5;
    border: 1px solid #c9ab78;
    box-sizing: border-box;
    border-radius: 0px;
    height: 55px;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border .2s;
    cursor: text;
    color: #7d6336;
    font-size: 27px;
    font-family: BeaufortForLoL; }
    .form-control input[type="text"]:focus, .form-control input[type="password"]:focus, .form-control input[type="email"]:focus, .form-control input[type="number"]:focus {
      outline: none;
      border: 1px solid #bf9979;
      box-shadow: inset 0 0 10px 2px rgba(112, 117, 70, 0.2); }
    .form-control input[type="text"]::placeholder, .form-control input[type="password"]::placeholder, .form-control input[type="email"]::placeholder, .form-control input[type="number"]::placeholder {
      opacity: 0;
      pointer-events: none;
      color: #c9aa75;
      font-size: 27px;
      line-height: 32px;
      font-family: BeaufortForLoL;
      text-align: center;
      transition: opacity .2s; }
    .form-control input[type="text"] + label, .form-control input[type="password"] + label, .form-control input[type="email"] + label, .form-control input[type="number"] + label {
      order: 1;
      position: absolute;
      opacity: 1;
      pointer-events: all;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .2s; }
      .form-control input[type="text"] + label .label, .form-control input[type="password"] + label .label, .form-control input[type="email"] + label .label, .form-control input[type="number"] + label .label {
        color: #c9aa75;
        font-size: 27px;
        font-family: BeaufortForLoL;
        text-align: center; }
    .form-control input[type="text"]:focus + label, .form-control input[type="password"]:focus + label, .form-control input[type="email"]:focus + label, .form-control input[type="number"]:focus + label {
      opacity: 0;
      pointer-events: none; }
    .form-control input[type="text"]:focus::placeholder, .form-control input[type="password"]:focus::placeholder, .form-control input[type="email"]:focus::placeholder, .form-control input[type="number"]:focus::placeholder {
      opacity: 0.6; }
  .form-control input[type="checkbox"], .form-control input[type="radio"] {
    order: 1;
    background: #c4c4c4;
    border: 1px solid #CFCFCF;
    box-sizing: border-box;
    border-radius: 0;
    width: 10px;
    height: 10px;
    padding: 9px;
    margin-right: 10px;
    -webkit-appearance: none;
    transition: all .2s;
    transform: scale(0.8);
    transform-origin: center center; }
    .form-control input[type="checkbox"], .form-control input[type="checkbox"]:focus, .form-control input[type="radio"], .form-control input[type="radio"]:focus {
      outline: none; }
    .form-control input[type="checkbox"]:checked, .form-control input[type="radio"]:checked {
      transform: scale(1);
      background: transparent;
      border: 1px solid #1e2d62;
      box-shadow: 0 0 0 1px #1e2d62; }
      .form-control input[type="checkbox"]:checked:before, .form-control input[type="radio"]:checked:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        background: #1e2d62;
        top: 2px;
        left: 2px; }
    .form-control input[type="checkbox"]:disabled, .form-control input[type="radio"]:disabled {
      background: #282828; }
    .form-control input[type="checkbox"] + label, .form-control input[type="radio"] + label {
      width: calc(100% - 40px);
      order: 2;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer; }
      .form-control input[type="checkbox"] + label a, .form-control input[type="radio"] + label a {
        color: rgba(255, 255, 255, 0.7);
        font-weight: bold;
        text-decoration: none; }
  .form-control input[type="radio"] {
    border-radius: 12px; }
    .form-control input[type="radio"]:checked:before {
      border-radius: 12px; }
  .form-control--search input[type="text"] {
    background: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    color: #fff;
    width: 320px;
    height: 60px;
    font-size: 20px; }
    .form-control--search input[type="text"]:focus {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.9);
      box-shadow: none; }
  .form-control select + .select2 {
    order: 2;
    width: 100% !important; }
    .form-control select + .select2 .select2-selection {
      background: #FFFFFF;
      border: 2px solid #CFCFCF;
      box-sizing: border-box;
      border-radius: 0px;
      height: 40px;
      padding: 10px;
      transition: border .2s; }
      .form-control select + .select2 .select2-selection__rendered {
        line-height: 18px;
        color: #949494;
        padding: 0; }
      .form-control select + .select2 .select2-selection__arrow {
        width: 15px;
        height: 10px;
        background: url(../svg/arrow-down.svg) 0 0 no-repeat;
        transition: transform .2s;
        position: absolute;
        top: calc(50% - 10px / 2);
        right: calc(10px + 5px / 2); }
        .form-control select + .select2 .select2-selection__arrow b {
          display: none; }
    .form-control select + .select2.select2-container--open .selection .select2-selection__arrow {
      transform: rotate(180deg); }
    .form-control select + .select2.select2-container--focus .selection .select2-selection {
      border: 1px solid #2596E8;
      box-shadow: 0 0 0 1px #2596E8; }
    .form-control select + .select2 + label {
      order: 1;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #949494;
      margin-bottom: 8px; }
  .form-control .g-recaptcha {
    height: 78px;
    margin: 0 auto; }
  .form-control button {
    width: 84%;
    height: 80px;
    font-family: Roboto;
    font-weight: bold;
    font-stretch: condensed;
    text-transform: uppercase;
    padding: 8px 30px;
    border: none;
    font-size: 18px;
    line-height: 23px;
    cursor: pointer;
    margin: 0 auto;
    background: #861c00;
    transition: all .2s; }
    .form-control button:hover {
      background: #9b0000; }
    .form-control button .label {
      display: inline-block;
      font-family: Oswald;
      font-size: 34px;
      text-transform: uppercase;
      color: #ffe3b4;
      position: relative;
      padding: 0 20px; }
      .form-control button .label:before, .form-control button .label:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: #ffe3b4;
        position: absolute;
        top: calc(50% - 2px);
        transform: rotate(45deg); }
      .form-control button .label:before {
        left: 0; }
      .form-control button .label:after {
        right: 0; }
  .form-control--search button {
    width: auto;
    height: 40px;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: all .2s;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    padding: 0 20px;
    cursor: pointer; }
    .form-control--search button:hover {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.9); }

.select2-results__option {
  padding: 10px 20px !important;
  color: #555 !important; }
  .select2-results__option[aria-selected="true"] {
    background: #fcfcfc !important; }
    .select2-results__option[aria-selected="true"].select2-results__option--highlighted {
      background: #b8e2fd !important; }
  .select2-results__option--highlighted {
    background: #b8e2fd !important; }

.popup {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center; }
  .popup__background, .popup__background--unclosable {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    transition: opacity 1s; }
  .popup__content {
    opacity: 0;
    position: absolute;
    z-index: 2;
    transition: all .2s;
    transform: scale(0); }
  .popup__toolbar {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.1);
    transition: all .2s;
    padding: 5px;
    display: flex;
    justify-content: flex-end; }
    .popup__toolbar .close {
      width: 50px;
      height: 50px;
      display: flex;
      background: #000;
      justify-content: center;
      align-items: center;
      position: absolute; }
      .popup__toolbar .close svg {
        display: block;
        width: 32px;
        height: 32px; }
        .popup__toolbar .close svg path:not(:last-child) {
          fill: #FFFFFF; }
        .popup__toolbar .close svg path:last-child {
          stroke: #FFFFFF; }
  .popup.active {
    opacity: 1;
    pointer-events: all; }
    .popup.active .popup__background, .popup.active .popup__background--unclosable {
      opacity: 1; }
    .popup.active .popup__content {
      opacity: 1;
      transform: scale(1); }
    .popup.active .popup__toolbar {
      opacity: 1; }

.popup--open-image .popup__content .image img {
  display: block;
  max-width: 100%;
  max-height: 100%; }

.popup--open-video .popup__content {
  width: 1200px;
  height: 670px; }
  @media (max-width: 1023px) and (orientation: portrait) {
    .popup--open-video .popup__content {
      width: 680px;
      height: 385px; } }
  .popup--open-video .popup__content .embed {
    width: 100%;
    height: 100%; }
    .popup--open-video .popup__content .embed iframe {
      display: block;
      width: 100%;
      height: 100%; }

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  max-width: calc(100% - 16px * 2);
  margin: 0 auto;
  pointer-events: all; }
  .title .label {
    text-align: center; }
  .title--sub .label {
    display: inline-block;
    font-family: Oswald;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ae632c;
    position: relative;
    padding: 0 20px; }
    .title--sub .label:before, .title--sub .label:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #ae632c;
      position: absolute;
      top: calc(50% - 2px);
      transform: rotate(45deg); }
    .title--sub .label:before {
      left: 0; }
    .title--sub .label:after {
      right: 0; }
  .title--headline .label {
    display: inline-block;
    font-family: Oswald;
    font-size: 60px;
    line-height: 68px;
    text-transform: uppercase;
    color: #b98300;
    position: relative;
    padding: 0 20px; }
    .title--headline .label:before, .title--headline .label:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #b98300;
      position: absolute;
      top: calc(50% - 2px);
      transform: rotate(45deg); }
    .title--headline .label:before {
      left: 0; }
    .title--headline .label:after {
      right: 0; }
  .title--popup .label {
    display: inline-block;
    font-family: Oswald;
    font-size: 50px;
    line-height: 70px;
    text-transform: uppercase;
    font-weight: bold;
    color: #bf4009;
    position: relative;
    padding: 0 20px; }
    .title--popup .label:before, .title--popup .label:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #bf4009;
      position: absolute;
      top: calc(50% - 2px);
      transform: rotate(45deg); }
    .title--popup .label:before {
      left: 0; }
    .title--popup .label:after {
      right: 0; }
  .title--tabsnews {
    display: block;
    position: relative;
    pointer-events: all;
    border-bottom: 1px solid #c9ae81;
    margin-bottom: 16px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .title--tabsnews {
        border-bottom: 0px; } }
    .title--tabsnews:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #c9ae81;
      transform: rotate(45deg);
      position: absolute;
      top: 91%;
      left: 0; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .title--tabsnews:before {
          display: none; } }
    .title--tabsnews ul {
      width: 100%;
      display: flex;
      justify-content: flex-start; }
      .title--tabsnews ul li a {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        display: block;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 17px;
        text-decoration: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .title--tabsnews ul li a {
            color: #6c93d5;
            font-size: 32px;
            line-height: 42px;
            height: 50px; } }
        .title--tabsnews ul li a.active {
          position: relative; }
          .title--tabsnews ul li a.active:after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border-width: 5px;
            border-style: solid;
            border-color: #c9ae81 transparent transparent #c9ae81;
            position: absolute;
            transform: rotate(45deg);
            top: calc(100% - 5px); }
          @media (max-width: 1023px) and (orientation: portrait) {
            .title--tabsnews ul li a.active {
              color: #2955b4;
              font-weight: bold; }
              .title--tabsnews ul li a.active:after {
                width: 100%;
                height: 100%;
                border: 1px solid #1656b2;
                transform: rotate(0deg);
                top: 0;
                left: 0;
                border-radius: 12px; } }
        .title--tabsnews ul li a:hover {
          position: relative; }
          .title--tabsnews ul li a:hover:after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border-width: 5px;
            border-style: solid;
            border-color: #eee transparent transparent #eee;
            position: absolute;
            transform: rotate(45deg);
            top: calc(100% - 5px); }
      .title--tabsnews ul li:last-child {
        margin-left: auto; }
  .title--tabssub {
    display: block;
    position: relative;
    pointer-events: all; }
    .title--tabssub ul {
      width: 100%;
      display: flex;
      justify-content: center; }
      .title--tabssub ul li a {
        font-size: 24px;
        font-weight: 400;
        display: block;
        height: 44px;
        margin: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 17px;
        color: #6c93d5;
        text-decoration: none; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .title--tabssub ul li a {
            font-size: 32px;
            line-height: 42px;
            height: 50px; } }
        .title--tabssub ul li a.active {
          position: relative;
          color: #2955b4;
          font-weight: bold; }
          .title--tabssub ul li a.active:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid #2955b4;
            top: 0;
            left: 0;
            border-radius: 12px; }
        .title--tabssub ul li a:hover {
          position: relative; }
          .title--tabssub ul li a:hover:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid #9bbbf0;
            top: 0;
            left: 0;
            border-radius: 12px; }
  .title--tabsrank {
    display: block;
    position: relative;
    pointer-events: all;
    width: 1120px; }
    .title--tabsrank:before {
      content: "";
      display: block;
      width: 100%;
      height: 60px;
      border-width: 1px;
      border-style: solid;
      border-color: #b39b73 #b39b73 transparent #b39b73;
      position: absolute;
      top: 35%;
      left: 0; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .title--tabsrank:before {
          width: 80%;
          left: 10%; } }
    .title--tabsrank ul {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 20px; }
      .title--tabsrank ul:before, .title--tabsrank ul:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: #b39b73;
        position: absolute;
        top: 35%; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .title--tabsrank ul:before, .title--tabsrank ul:after {
            display: none; } }
      .title--tabsrank ul:before {
        left: 0; }
      .title--tabsrank ul:after {
        right: 0; }
      .title--tabsrank ul li {
        margin: 0 7px; }
        .title--tabsrank ul li a {
          color: #1e3f51;
          font-size: 32px;
          font-weight: 400;
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -376px -484px;
          width: 342px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none; }
          .title--tabsrank ul li a:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: 0px -708px;
            width: 342px;
            height: 70px;
            color: #ffffff; }
          .title--tabsrank ul li a.active {
            background-image: url(../images/_sprites-full.png);
            background-position: 0px -708px;
            width: 342px;
            height: 70px;
            color: #ffffff; }
            .title--tabsrank ul li a.active:hover {
              filter: brightness(1.3); }
        @media (max-width: 1023px) and (orientation: portrait) {
          .title--tabsrank ul li:nth-child(1), .title--tabsrank ul li:nth-child(3) {
            transform: scale(0.5); }
          .title--tabsrank ul li:nth-child(1) {
            transform-origin: center right; }
          .title--tabsrank ul li:nth-child(3) {
            transform-origin: center left; } }
  .title--tabsgallery {
    display: block;
    position: relative;
    pointer-events: all;
    width: 1200px;
    height: 50px;
    top: 80px; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .title--tabsgallery {
        top: 0; } }
    .title--tabsgallery:before {
      content: "";
      display: block;
      background-image: url(../images/_sprites-full.png);
      background-position: -370px -858px;
      width: 691px;
      height: 5px;
      position: absolute;
      top: 24px;
      left: -58px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .title--tabsgallery:before {
          left: 0; } }
    .title--tabsgallery ul {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-bottom: 20px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .title--tabsgallery ul {
          justify-content: center; } }
      .title--tabsgallery ul li {
        margin: 0 7px; }
        .title--tabsgallery ul li a {
          color: #24344d;
          font-size: 28px;
          font-weight: 400;
          display: block;
          background-image: url(../images/_sprites-full.png);
          background-position: -542px -708px;
          width: 174px;
          height: 49px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none; }
          .title--tabsgallery ul li a:hover {
            background-image: url(../images/_sprites-full.png);
            background-position: -716px -708px;
            width: 174px;
            height: 49px;
            color: #24344d; }
          .title--tabsgallery ul li a.active {
            background-image: url(../images/_sprites-full.png);
            background-position: -716px -708px;
            width: 174px;
            height: 49px;
            color: #24344d; }
  .title--main {
    z-index: 3;
    height: 152px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .title--main:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-image: url(../images/_sprites-full.png);
      background-position: 0px -152px;
      width: 750px;
      height: 152px; }
    .title--main .label {
      display: inline-block;
      font-family: SukhumvitSet;
      font-size: 72px;
      line-height: 86px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      padding: 0 20px; }

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 2; }
  .button:hover {
    cursor: pointer; }
  .button--default {
    background: #1e2d62;
    padding: 13px 16px; }
    .button--default .label {
      font-family: Roboto;
      color: #000000;
      font-weight: bold;
      font-stretch: condensed;
      font-size: 18px; }
  .button--default-mini {
    background: rgba(30, 45, 98, 0.1);
    padding: 8px 8px; }
    .button--default-mini .label {
      font-family: Roboto;
      color: #000000;
      font-size: 14px; }
  .button--navigation {
    background: transparent;
    height: 84px;
    padding: 0 42px; }
    @media (max-width: 767px) {
      .button--navigation {
        height: 54px;
        width: 100% !important; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      .button--navigation {
        height: 54px;
        width: 100% !important; } }
    .button--navigation .label {
      text-transform: uppercase;
      font-size: 22px;
      line-height: 28px;
      color: #3355ab; }
    .button--navigation .icon[data-icon$="arrow"] {
      display: block;
      width: 7px;
      transform: rotate(90deg);
      height: 16px;
      margin-left: 13px;
      margin-top: 2px; }
    .button--navigation .icon[data-icon^="lang"] {
      display: block;
      width: 42px;
      height: 28px;
      margin-top: -4px; }
      @media (max-width: 767px) {
        .button--navigation .icon[data-icon^="lang"] {
          width: 28px;
          height: 14px; } }
      @media (min-width: 768px) and (max-width: 1279px) {
        .button--navigation .icon[data-icon^="lang"] {
          width: 28px;
          height: 14px; } }
    .button--navigation:hover:not(.button--navigation--lang), .button--navigation.active {
      background: #1e2d62; }
      .button--navigation:hover:not(.button--navigation--lang) .label, .button--navigation.active .label {
        color: #FFFFFF;
        text-shadow: 0px 0px 1px white; }
      .button--navigation:hover:not(.button--navigation--lang) .icon:not([data-icon^="lang"]) svg path, .button--navigation.active .icon:not([data-icon^="lang"]) svg path {
        fill: #fff; }
    .button--navigation--sub {
      height: 42px; }
      .button--navigation--sub .icon[data-icon^="lang"] {
        display: block;
        width: 27px;
        height: 21px;
        margin-top: -4px; }
    .button--navigation--lang {
      margin: 0 auto; }
    .button--navigation.logo:hover {
      background: none; }
  .button--tab {
    background: #111111;
    border: 1px solid #424242;
    padding: 13px 44px;
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    @media (max-width: 767px) {
      .button--tab {
        padding: 13px 20px; } }
    .button--tab .label {
      font-family: Roboto;
      color: #FFFFFF;
      font-size: 22px;
      font-weight: normal; }
    .button--tab:hover, .button--tab.active {
      background: #1e2d62;
      border: 1px solid #1e2d62; }
      .button--tab:hover .label, .button--tab.active .label {
        color: #000000;
        font-weight: bold; }
    .button--tab.active:after {
      content: "";
      display: block;
      background: #1e2d62;
      position: absolute;
      bottom: -6px;
      left: calc(50% - 12px / 2);
      width: 12px;
      height: 12px;
      transform: rotate(45deg); }
  .button--login {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 13px 16px;
    border-radius: 8px; }
    .button--login--zingid {
      background: #0AA5E8; }
      .button--login--zingid:hover {
        background: #17b2f5; }
    .button--login--facebook {
      background: #1776CA; }
      .button--login--facebook:hover {
        background: #1a83e1; }
    .button--login--google {
      background: #DF4930; }
      .button--login--google:hover {
        background: #e25c46; }
    .button--login .label {
      font-family: Roboto;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px; }
    .button--login:hover {
      cursor: pointer; }
  .button--nothing .label {
    color: #FFFFFF; }
  .button--foggy_in {
    background: transparent;
    padding: 25px 16px; }
    @media (max-width: 767px) {
      .button--foggy_in {
        padding: 22px 8px;
        text-align: center; } }
    .button--foggy_in .label {
      font-family: Roboto;
      color: #FFFFFF;
      font-weight: bold;
      font-stretch: condensed;
      font-size: 18px;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .button--foggy_in .label {
          font-size: 13px; } }
    .button--foggy_in .foggy_in__corner {
      opacity: 0;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
      .button--foggy_in .foggy_in__corner--top:before, .button--foggy_in .foggy_in__corner--top:after, .button--foggy_in .foggy_in__corner--bottom:before, .button--foggy_in .foggy_in__corner--bottom:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 1px; }
      .button--foggy_in .foggy_in__corner--top:before {
        border-color: #1e2d62 transparent transparent #1e2d62;
        top: 5px;
        left: 5px; }
      .button--foggy_in .foggy_in__corner--top:after {
        border-color: #1e2d62 #1e2d62 transparent transparent;
        top: 5px;
        right: 5px; }
      .button--foggy_in .foggy_in__corner--bottom:before {
        border-color: transparent transparent #1e2d62 #1e2d62;
        bottom: 5px;
        left: 5px; }
      .button--foggy_in .foggy_in__corner--bottom:after {
        border-color: transparent #1e2d62 #1e2d62 transparent;
        bottom: 5px;
        right: 5px; }
    .button--foggy_in:hover, .button--foggy_in.active, .button--foggy_in:focus {
      background: rgba(40, 40, 40, 0.45); }
      .button--foggy_in:hover .label, .button--foggy_in.active .label, .button--foggy_in:focus .label {
        color: #1e2d62; }
    .button--foggy_in.active .foggy_in__corner, .button--foggy_in:focus .foggy_in__corner {
      opacity: 1; }
  .button--foggy_out {
    background: transparent;
    padding: 25px 16px; }
    .button--foggy_out .label {
      font-family: Roboto;
      color: #FFFFFF;
      font-weight: bold;
      font-stretch: condensed;
      font-size: 18px;
      text-transform: uppercase; }
    .button--foggy_out .foggy_out__corner {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none; }
      .button--foggy_out .foggy_out__corner--top:before, .button--foggy_out .foggy_out__corner--top:after, .button--foggy_out .foggy_out__corner--bottom:before, .button--foggy_out .foggy_out__corner--bottom:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 1px; }
      .button--foggy_out .foggy_out__corner--top:before {
        border-color: #1e2d62 transparent transparent #1e2d62;
        top: -5px;
        left: -5px; }
      .button--foggy_out .foggy_out__corner--top:after {
        border-color: #1e2d62 #1e2d62 transparent transparent;
        top: -5px;
        right: -5px; }
      .button--foggy_out .foggy_out__corner--bottom:before {
        border-color: transparent transparent #1e2d62 #1e2d62;
        bottom: -5px;
        left: -5px; }
      .button--foggy_out .foggy_out__corner--bottom:after {
        border-color: transparent #1e2d62 #1e2d62 transparent;
        bottom: -5px;
        right: -5px; }
    .button--foggy_out:hover, .button--foggy_out.active, .button--foggy_out:focus, .button--foggy_out.swiper-pagination-bullet-active {
      background: rgba(40, 40, 40, 0.45); }
      .button--foggy_out:hover .label, .button--foggy_out.active .label, .button--foggy_out:focus .label, .button--foggy_out.swiper-pagination-bullet-active .label {
        color: #FFFFFF; }
    .button--foggy_out.active .foggy_out__corner, .button--foggy_out:focus .foggy_out__corner, .button--foggy_out.swiper-pagination-bullet-active .foggy_out__corner {
      opacity: 1; }
  .button--dark_out {
    background: #282828;
    padding: 13px 56px; }
    .button--dark_out .label {
      font-family: Roboto;
      color: #fff;
      font-weight: bold;
      font-stretch: condensed;
      font-size: 24px;
      text-transform: uppercase; }
    .button--dark_out .dark_out__corner {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
      .button--dark_out .dark_out__corner--top:before, .button--dark_out .dark_out__corner--top:after, .button--dark_out .dark_out__corner--bottom:before, .button--dark_out .dark_out__corner--bottom:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 1px; }
      .button--dark_out .dark_out__corner--top:before {
        border-color: #424242 transparent transparent #424242;
        top: -5px;
        left: -5px; }
      .button--dark_out .dark_out__corner--top:after {
        border-color: #424242 #424242 transparent transparent;
        top: -5px;
        right: -5px; }
      .button--dark_out .dark_out__corner--bottom:before {
        border-color: transparent transparent #424242 #424242;
        bottom: -5px;
        left: -5px; }
      .button--dark_out .dark_out__corner--bottom:after {
        border-color: transparent #424242 #424242 transparent;
        bottom: -5px;
        right: -5px; }
  .button--light_out {
    background: #1e2d62;
    padding: 10px 16px; }
    .button--light_out .label {
      font-family: Roboto;
      color: #322626;
      font-weight: bold;
      font-stretch: condensed;
      font-size: 22px;
      text-transform: uppercase; }
    .button--light_out.special {
      padding: 16px 38px; }
      .button--light_out.special .label {
        font-size: 24px; }
    .button--light_out .light_out__corner {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
      .button--light_out .light_out__corner--top:before, .button--light_out .light_out__corner--top:after, .button--light_out .light_out__corner--bottom:before, .button--light_out .light_out__corner--bottom:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 1px; }
      .button--light_out .light_out__corner--top:before {
        border-color: #FFFFFF transparent transparent #FFFFFF;
        top: -5px;
        left: -5px; }
      .button--light_out .light_out__corner--top:after {
        border-color: #FFFFFF #FFFFFF transparent transparent;
        top: -5px;
        right: -5px; }
      .button--light_out .light_out__corner--bottom:before {
        border-color: transparent transparent #FFFFFF #FFFFFF;
        bottom: -5px;
        left: -5px; }
      .button--light_out .light_out__corner--bottom:after {
        border-color: transparent #FFFFFF #FFFFFF transparent;
        bottom: -5px;
        right: -5px; }
  .button--nav_sub {
    width: 280px;
    height: 60px;
    background: transparent;
    display: flex;
    justify-content: start;
    padding: 0 12px; }
    .button--nav_sub .icon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 12px; }
      .button--nav_sub .icon img {
        height: 80%;
        width: auto; }
    .button--nav_sub .label {
      height: 100%;
      font-family: Roboto;
      color: #FFFFFF;
      font-weight: bold;
      font-stretch: condensed;
      display: flex;
      align-items: center;
      font-size: 16px; }
    .button--nav_sub:hover {
      background: #1e2d62; }
      .button--nav_sub:hover .label {
        color: #000000; }

.border_news {
  width: 780px;
  height: 310px;
  display: flex;
  flex-wrap: wrap; }
  .border_news--top-left {
    display: block;
    width: 17px;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-corner_top_left.png); }
  .border_news--top-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-corner_top_right.png); }
  .border_news--top-loop {
    display: block;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-loop_top.png);
    width: calc(100% - 17px - 16px); }
  .border_news--left-loop {
    display: block;
    width: 17px;
    background: url(../../dist/assets/images/news_border/news_border-loop_left.png);
    height: calc(100% - 16px - 16px); }
  .border_news--content {
    background: #FFF;
    width: calc(100% - 17px - 16px);
    height: calc(100% - 16px - 16px); }
  .border_news--right-loop {
    display: block;
    width: 16px;
    background: url(../../dist/assets/images/news_border/news_border-loop_right.png);
    height: calc(100% - 16px - 16px); }
  .border_news--bottom-left {
    display: block;
    width: 17px;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-corner_bottom_left.png); }
  .border_news--bottom-loop {
    display: block;
    width: 17px;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-loop_bottom.png);
    width: calc(100% - 17px - 16px); }
  .border_news--bottom-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/news_border/news_border-corner_bottom_right.png); }
  @media (max-width: 1023px) and (orientation: portrait) {
    .border_news {
      width: 690px;
      height: auto; }
      .border_news--top-left, .border_news--top-right, .border_news--top-loop, .border_news--left-loop, .border_news--right-loop, .border_news--bottom-left, .border_news--bottom-right, .border_news--bottom-loop {
        display: none; }
      .border_news--content {
        background: transparent;
        width: 100%;
        height: 100%; } }

.border_rank {
  width: 1180px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .border_rank:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% + -12px);
    height: calc(100% + -12px);
    top: 6px;
    left: 6px;
    background: url(../../dist/assets/images/bg/list-rank.jpg); }
  .border_rank--top-left {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_top_left.png); }
  .border_rank--top-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_top_right.png); }
  .border_rank--top-loop {
    display: block;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_top.png);
    width: calc(100% - 16px - 16px); }
  .border_rank--left-loop {
    display: block;
    width: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_left.png);
    height: calc(100% - 16px - 16px); }
  .border_rank--content {
    position: relative;
    width: calc(100% - 16px - 16px);
    height: calc(100% - 16px - 16px); }
  .border_rank--right-loop {
    display: block;
    width: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_right.png);
    height: calc(100% - 16px - 16px); }
  .border_rank--bottom-left {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_left.png); }
  .border_rank--bottom-loop {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_bottom.png);
    width: calc(100% - 16px - 16px); }
  .border_rank--bottom-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_right.png); }
  @media (max-width: 1023px) and (orientation: portrait) {
    .border_rank {
      width: 690px;
      height: 600px; } }

.border_gallery {
  width: 430px;
  height: 230px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .border_gallery--top-left {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_top_left.png); }
  .border_gallery--top-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_top_right.png); }
  .border_gallery--top-loop {
    display: block;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_top.png);
    width: calc(100% - 16px - 16px); }
  .border_gallery--left-loop {
    display: block;
    width: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_left.png);
    height: calc(100% - 16px - 16px); }
  .border_gallery--content {
    position: relative;
    width: calc(100% - 16px - 16px);
    height: calc(100% - 16px - 16px); }
    .border_gallery--content img {
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      object-fit: cover;
      position: absolute;
      top: -6px;
      left: -6px; }
  .border_gallery--right-loop {
    display: block;
    width: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_right.png);
    height: calc(100% - 16px - 16px); }
  .border_gallery--bottom-left {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_left.png); }
  .border_gallery--bottom-loop {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-loop_bottom.png);
    width: calc(100% - 16px - 16px); }
  .border_gallery--bottom-right {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_right.png); }
  @media (max-width: 1023px) and (orientation: portrait) {
    .border_gallery {
      width: 690px;
      height: 372px; } }

.news {
  z-index: 2; }
  .news__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .news__list-sub {
      margin: 0 75px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__list-sub {
          margin: 0 24px; } }
  .news__single {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    height: 46px;
    overflow: hidden; }
    .news__single:not(:last-child) {
      border-bottom: 1px solid #afc5d5; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .news__single {
        height: 72px; }
        .news__single:not(:last-child) {
          border-bottom: 1px solid #afc5d5; } }
    .news__single .cate, .news__single .name, .news__single .time {
      display: inline-flex;
      align-items: center;
      color: #000;
      text-decoration: none; }
      .news__single .cate:not(.name), .news__single .name:not(.name), .news__single .time:not(.name) {
        justify-content: center; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single .cate, .news__single .name, .news__single .time {
          font-size: 26px;
          line-height: 32px; } }
    .news__single .cate {
      width: 90px;
      position: relative;
      z-index: 2;
      font-size: 14px; }
      .news__single .cate:before {
        content: "";
        display: block;
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        background: #d9e5ff;
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: -1;
        border: 1px solid #558cbd;
        border-radius: 4px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single .cate {
          display: none; } }
    .news__single .time {
      width: 90px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single .time {
          display: none; } }
    .news__single .name {
      width: calc(100% - 90px - 90px);
      justify-content: flex-start;
      padding: 0 14px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single .name {
          width: 100%;
          padding: 0 0px 0 32px; }
          .news__single .name:before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #2955b4;
            transform: rotate(45deg);
            position: absolute;
            left: 9px;
            top: calc(50% - 7px); } }
    .news__single--hot .cate {
      background: #ffd05f;
      font-weight: bold;
      color: #FFF;
      font-size: 18px; }
      .news__single--hot .cate:before {
        display: none; }
    .news__single--hot .name {
      background: #d9e5ff;
      font-weight: bold; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single--hot .name {
          background: transparent;
          color: #2955b4; }
          .news__single--hot .name:before {
            background: #2955b4; } }
    .news__single--hot .time {
      font-size: 0;
      background: #d9e5ff; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single--hot .time {
          background: transparent; } }
    .news__single:hover.news__single--hot .name {
      color: #000; }
    .news__single:hover.news__single .name {
      color: #912600; }
  .news__single-sub {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    overflow: hidden; }
    .news__single-sub:not(:last-child) {
      border-bottom: 1px solid #afc5d5; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .news__single-sub:not(:last-child) {
        border-bottom: 1px solid #afc5d5; } }
    .news__single-sub .cate, .news__single-sub .name, .news__single-sub .time {
      display: inline-flex;
      align-items: center;
      color: #000;
      text-decoration: none;
      justify-content: flex-start;
      padding: 14px 12px;
      text-align: left; }
      .news__single-sub .cate.time, .news__single-sub .name.time, .news__single-sub .time.time {
        justify-content: flex-end; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub .cate, .news__single-sub .name, .news__single-sub .time {
          font-size: 26px;
          line-height: 32px; } }
    .news__single-sub .cate {
      width: 160px;
      position: relative;
      z-index: 2;
      font-size: 20px;
      color: #665d51;
      font-weight: 600; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub .cate {
          display: none; } }
    .news__single-sub .time {
      width: 100px;
      color: #929191;
      font-size: 16px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub .time {
          display: none; } }
    .news__single-sub .name {
      width: calc(100% - 160px - 100px);
      justify-content: flex-start;
      padding: 0 14px;
      color: #665d51;
      font-size: 18px;
      line-height: 32px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub .name {
          font-size: 26px;
          width: 100%;
          padding: 14px 12px 14px 32px; }
          .news__single-sub .name:before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #2955b4;
            transform: rotate(45deg);
            position: absolute;
            left: 9px;
            top: calc(50% - 7px); } }
    .news__single-sub--hot .cate {
      background: #ffd05f;
      font-weight: bold;
      color: #FFF;
      font-size: 18px; }
      .news__single-sub--hot .cate:before {
        display: none; }
    .news__single-sub--hot .name {
      background: #d9e5ff;
      font-weight: bold; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub--hot .name {
          background: transparent;
          color: #2955b4; }
          .news__single-sub--hot .name:before {
            background: #2955b4; } }
    .news__single-sub--hot .time {
      background: #d9e5ff; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .news__single-sub--hot .time {
          background: transparent; } }
    .news__single-sub:hover.news__single-sub--hot .name {
      color: #000; }
    .news__single-sub:hover.news__single-sub .name {
      color: #912600; }
  .news__content {
    position: relative;
    z-index: 2;
    background: #fafafa;
    padding: 20px;
    margin-top: -100px; }
    .news__content:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100px;
      box-shadow: 0 -40px 30px 0 rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      z-index: -1; }
      @media (max-width: 767px) {
        .news__content:before {
          display: none; } }
    @media (max-width: 767px) {
      .news__content {
        background: transparent;
        margin-top: 40px; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      .news__content {
        background: transparent;
        margin-top: 40px; } }
    .news__content__breadcrumbs ul {
      display: flex;
      justify-content: flex-start; }
      .news__content__breadcrumbs ul li {
        margin-right: 4px; }
        .news__content__breadcrumbs ul li a {
          text-decoration: none; }
          .news__content__breadcrumbs ul li a .label {
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            color: #111111; }
          .news__content__breadcrumbs ul li a .icon {
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 16px; }
            .news__content__breadcrumbs ul li a .icon svg {
              width: 100%;
              height: 100%;
              object-fit: contain; }
              .news__content__breadcrumbs ul li a .icon svg path {
                fill: #000000; }
    .news__content__title .label {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px; }
    .news__content__meta .time {
      color: #949494;
      line-height: 16px;
      font-size: 14px;
      vertical-align: text-top;
      margin-right: 16px; }
  .news__page {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 24px 0; }
    .news__page ul {
      display: flex;
      justify-self: center; }
      .news__page ul li {
        margin: 0 8px; }
        .news__page ul li, .news__page ul li .page, .news__page ul li .text {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 42px; }
        .news__page ul li .prev {
          font-size: 0;
          background-image: url(../images/_sprites-full.png);
          background-position: -1223px -90px;
          width: 16px;
          height: 27px; }
        .news__page ul li .next {
          font-size: 0;
          background-image: url(../images/_sprites-full.png);
          background-position: -1223px -117px;
          width: 16px;
          height: 27px; }
        .news__page ul li .page {
          width: 42px;
          height: 42px;
          color: #5b5b5b;
          font-weight: bold;
          text-decoration: none; }
          .news__page ul li .page.active {
            background: #f3f3eb;
            border: 1px solid #dfdfdf; }
          .news__page ul li .page:hover {
            position: relative;
            z-index: 2; }
            .news__page ul li .page:hover:before {
              content: "";
              display: block;
              width: 80%;
              height: 80%;
              position: absolute;
              z-index: -1;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              border-width: 1px;
              border-style: solid;
              border-color: #dfdfdf;
              background: rgba(243, 243, 235, 0.2); }
        .news__page ul li .text {
          height: 42px;
          width: 42px; }
  .news .swiper-container-newsList .swiper-wrapper {
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
    .news .swiper-container-newsList .swiper-wrapper .swiper-slide {
      display: flex;
      justify-content: center; }
  .news .swiper-container-newsList .swiper-button-next-newsList, .news .swiper-container-newsList .swiper-button-prev-newsList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 80px;
    background: rgba(255, 255, 255, 0.75);
    transition: background-color 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    @media (max-width: 767px) {
      .news .swiper-container-newsList .swiper-button-next-newsList, .news .swiper-container-newsList .swiper-button-prev-newsList {
        height: 40px; } }
    .news .swiper-container-newsList .swiper-button-next-newsList:before, .news .swiper-container-newsList .swiper-button-next-newsList:after, .news .swiper-container-newsList .swiper-button-prev-newsList:before, .news .swiper-container-newsList .swiper-button-prev-newsList:after {
      display: none; }
    .news .swiper-container-newsList .swiper-button-next-newsList:hover, .news .swiper-container-newsList .swiper-button-prev-newsList:hover {
      background: white; }
    .news .swiper-container-newsList .swiper-button-next-newsList .icon svg, .news .swiper-container-newsList .swiper-button-prev-newsList .icon svg {
      transform-origin: center center;
      width: 14px;
      height: 14px; }
  .news .swiper-container-newsList .swiper-button-next-newsList {
    right: 0; }
  .news .swiper-container-newsList .swiper-button-prev-newsList {
    left: 0; }
    .news .swiper-container-newsList .swiper-button-prev-newsList .icon svg {
      transform: scaleX(-1); }

.events {
  z-index: 2; }
  .events__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .events__single {
    width: 780px;
    height: 310px; }
    .events__single .thumbnail {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      position: absolute;
      left: 5px;
      top: 5px; }
      .events__single .thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .events__single .link {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 0; }
  .events .swiper-pagination-listEvents {
    bottom: initial;
    left: initial;
    left: initial;
    right: initial;
    top: initial;
    bottom: initial;
    position: absolute;
    bottom: 30px !important;
    padding: 0 60px;
    z-index: 5;
    display: flex;
    justify-content: flex-end; }
    .events .swiper-pagination-listEvents .swiper-pagination-bullet {
      opacity: 1;
      border-radius: 0;
      background: transparent;
      width: initial;
      height: initial;
      background-image: url(../images/_sprites-full.png);
      background-position: -1155px -799px;
      width: 29px;
      height: 28px; }
      .events .swiper-pagination-listEvents .swiper-pagination-bullet-active {
        background-image: url(../images/_sprites-full.png);
        background-position: -1215px -769px;
        width: 28px;
        height: 28px; }

.char {
  z-index: 2;
  pointer-events: all; }
  .char__single {
    width: 100%;
    height: 1160px;
    position: relative; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .char__single {
        height: 988px; } }
    .char__single .thumbnail {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 712px); }
      @media (max-width: 1023px) and (orientation: portrait) {
        .char__single .thumbnail {
          bottom: -4px;
          left: auto;
          right: 0; } }
      .char__single .thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .char__single .thumbnail video {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .char__single .headline {
      display: block;
      position: absolute;
      left: calc(50% + 200px);
      top: 0;
      width: 420px;
      height: 201px;
      background: url(../../dist/assets/images/bg/char-name.png);
      background-repeat: no-repeat;
      pointer-events: all;
      padding: 68px 0 50px 40px;
      box-sizing: border-box; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .char__single .headline {
          left: 20px; } }
      .char__single .headline .name {
        display: block;
        width: 100%;
        font-family: Cinzel;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 62px;
        text-align: center;
        color: #4c76df; }
        .char__single .headline .name--long {
          font-size: 44px;
          line-height: 62px; }
      .char__single .headline .tribe {
        display: block;
        width: 100%;
        font-family: Cinzel;
        font-size: 24px;
        text-align: center;
        color: #5181b2; }
        .char__single .headline .tribe:before, .char__single .headline .tribe:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #5181b2;
          position: relative;
          transform: rotate(45deg);
          top: -6px; }
        .char__single .headline .tribe:before {
          left: -15px; }
        .char__single .headline .tribe:after {
          right: -15px; }
    .char__single .info {
      display: block;
      position: absolute;
      left: calc(50% + 230px);
      top: 190px;
      width: 390px;
      height: 521px;
      background: url(../../dist/assets/images/bg/char-info.png);
      background-repeat: no-repeat;
      pointer-events: all;
      box-sizing: border-box;
      padding: 50px 30px; }
      @media (max-width: 1023px) and (orientation: portrait) {
        .char__single .info {
          left: 0;
          background: transparent; } }
      .char__single .info .description, .char__single .info .weapon, .char__single .info .difficulty {
        font-size: 22px;
        font-family: SukhumvitSet;
        line-height: 36px;
        color: #3355ab; }
        .char__single .info .description strong, .char__single .info .weapon strong, .char__single .info .difficulty strong {
          font-size: 28px;
          font-weight: bold; }
        .char__single .info .description .icon, .char__single .info .weapon .icon, .char__single .info .difficulty .icon {
          display: inline-block; }
          .char__single .info .description .icon svg path, .char__single .info .weapon .icon svg path, .char__single .info .difficulty .icon svg path {
            fill: #3355ab; }
      .char__single .info .feature {
        display: flex;
        justify-content: center; }
        @media (max-width: 1023px) and (orientation: portrait) {
          .char__single .info .feature {
            justify-content: flex-start; }
            .char__single .info .feature img {
              width: 220px;
              height: auto; } }
    .char__single .clip {
      display: block;
      pointer-events: all;
      position: absolute;
      top: 480px;
      left: calc(50% - 540px);
      background-image: url(../images/_sprites-full.png);
      background-position: -750px -440px;
      width: 132px;
      height: 134px; }
      .char__single .clip:hover {
        filter: brightness(120%); }
      @media (max-width: 1023px) and (orientation: portrait) {
        .char__single .clip {
          top: 620px;
          left: calc(50% - 314px); } }
  .char__page {
    background-image: url(../images/_sprites-full.png);
    background-position: -750px 0px;
    width: 170px;
    height: 440px;
    position: absolute;
    top: 35px;
    left: calc(50% - 558px);
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .char__page {
        position: static;
        background: url(../../dist/assets/images/bg/char-page.jpg);
        height: 130px;
        width: 100%; } }
