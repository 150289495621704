@import "../configs/mixins/clear_default";

.events {
    z-index: 2;

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__single {
        width: 780px;
        height: 310px;

        .thumbnail {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            position: absolute;
            left: 5px;
            top: 5px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .link {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            position: absolute;
            left: 5px;
            top: 5px;

            font-size: 0;
        }
    }

    .swiper-pagination-listEvents {
        @include clear_swiper_pagination;
        position: absolute;
        bottom: 30px !important;
        padding: 0 60px;
        z-index: 5;

        display: flex;
        justify-content: flex-end;

        .swiper-pagination-bullet {
            @include clear_swiper_pagination_bullet;
            @include sprite($events_page);
            &-active {
                @include sprite($events_page-active);
            }
        }
    }

}