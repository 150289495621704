.footer {
    position: relative;
    height: 543px;
    pointer-events: none;

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }

    }

    &__content {
        position: relative;
        height: 100%;
    }



    &__wrapper {
        position: absolute;
        width: 100%;
        font-size: 18px;
        color: #5b6a82;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        bottom: 180px;
        pointer-events: all;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include grid-media($portrait) {
            bottom: 80px;
        }

        p {
            width: 100%;
            line-height: 32px;
        }

        .logo_pw, .logo_vng {
            display: inline-flex;
            margin: 0 12px 12px;
        }

        .logo_vng {
            @include sprite($logo-vng)
        }

        .logo_pw {
            @include sprite($logo-pw)
        }
    }
}