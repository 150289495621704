.subpage {
    position: relative;
    // height: 543px;
    pointer-events: none;

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f5f9fd;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
        }

    }

    &__content {
        position: relative;
        height: 100%;
    }

    &__title {
        width: 1000px;
        height: 110px;
        max-width: 100%;
        background: url(../../dist/assets/images/bg/sub-title.jpg);
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
            font-size: 42px;
            font-weight: bold;
            color: #fefefe;
        }
    }

    &__wrapper {
        position: relative;
        width: 1000px;
        max-width: 100%;
        font-size: 18px;
        color: #5b6a82;
        left: 50%;
        transform: translateX(-50%) translateY(-400px);
        background: #fff;
        text-align: center;
        bottom: 180px;
        pointer-events: all;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include grid-media($portrait) {
            padding-bottom: 220px;
            bottom: 80px;
            background: transparent;

            transform: translateX(-50%) translateY(0);
        }

        p {
            width: 100%;
            line-height: 32px;
        }

        .logo_pw, .logo_vng {
            display: inline-flex;
            margin: 0 12px 12px;
        }

        .logo_vng {
            @include sprite($logo-vng)
        }

        .logo_pw {
            @include sprite($logo-pw)
        }
    }
}