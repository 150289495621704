.border_news {

    width: 780px;
    height: 310px;

    display: flex;
    flex-wrap: wrap;

    &--top-left {
        display:block;
        width: 17px;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-corner_top_left.png);
    }
    &--top-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-corner_top_right.png);

    }
    &--top-loop {
        display:block;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-loop_top.png);
        width: calc(100% - 17px - 16px);
    }

    &--left-loop {
        display:block;
        width: 17px;
        background: url(../../dist/assets/images/news_border/news_border-loop_left.png);
        height: calc(100% - 16px - 16px);
    }

    &--content {
        background: #FFF;
        width: calc(100% - 17px - 16px);
        height: calc(100% - 16px - 16px);
    }


    &--right-loop {
        display:block;
        width: 16px;
        background: url(../../dist/assets/images/news_border/news_border-loop_right.png);

        height: calc(100% - 16px - 16px);
    }
    &--bottom-left {
        display:block;
        width: 17px;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-corner_bottom_left.png);

    }
    &--bottom-loop {
        display:block;
        width: 17px;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-loop_bottom.png);
        width: calc(100% - 17px - 16px);
    }
    &--bottom-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/news_border/news_border-corner_bottom_right.png);

    }

    @include grid-media($portrait) {
        width: 690px;
        height: auto;
        &--top-left, &--top-right, &--top-loop, 
        &--left-loop, &--right-loop,
        &--bottom-left, &--bottom-right, &--bottom-loop {
            display: none;
        }

        &--content {
            background: transparent;
            width: 100%;
            height: 100%;
        }
    }
}

.border_rank {

    width: 1180px;
    height: 600px;

    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;

    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + -12px);
        height: calc(100% + -12px);
        top: 6px;
        left: 6px;
        background:url(../../dist/assets/images/bg/list-rank.jpg);
    }

    &--top-left {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_top_left.png);
    }
    &--top-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_top_right.png);

    }
    &--top-loop { 
        display:block;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_top.png);
        width: calc(100% - 16px - 16px);
    }

    &--left-loop {
        display:block;
        width: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_left.png);
        height: calc(100% - 16px - 16px);
    }

    &--content {

        position: relative;
        width: calc(100% - 16px - 16px);
        height: calc(100% - 16px - 16px);

    }


    &--right-loop {
        display:block;
        width: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_right.png);

        height: calc(100% - 16px - 16px);
    }
    &--bottom-left {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_left.png);

    }
    &--bottom-loop {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_bottom.png);
        width: calc(100% - 16px - 16px);  
    }
    &--bottom-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_right.png);

    }

    @include grid-media($portrait) {
        width: 690px;
        height: 600px;
        &--top-left, &--top-right, &--top-loop, 
        &--left-loop, &--right-loop,
        &--bottom-left, &--bottom-right, &--bottom-loop {
            // display: none;
        }

        &--content {
            // background: transparent;
            // width: 100%;
            // height: 100%;
        }
    }
}

.border_gallery {

    width: 430px;
    height: 230px;

    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;

    position: relative;
    // &:before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     width: calc(100% + -12px);
    //     height: calc(100% + -12px);
    //     top: 6px;
    //     left: 6px;
    //     background:url(../../dist/assets/images/bg/list-rank.jpg);
    // }

    &--top-left {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_top_left.png);
    }
    &--top-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_top_right.png);

    }
    &--top-loop { 
        display:block;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_top.png);
        width: calc(100% - 16px - 16px);
    }

    &--left-loop {
        display:block;
        width: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_left.png);
        height: calc(100% - 16px - 16px);
    }

    &--content {

        position: relative;
        width: calc(100% - 16px - 16px);
        height: calc(100% - 16px - 16px);
        // width: calc(100% + -12px);
        // height: calc(100% + -12px);
        // top: 6px;
        // left: 6px;

        img {
            width: calc(100% + 12px);
            height: calc(100% + 12px);
            object-fit: cover;
            position: absolute;
            //     width: calc(100% + -12px);
            //     height: calc(100% + -12px);
            top: -6px;
            left: -6px;
        }

    }


    &--right-loop {
        display:block;
        width: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_right.png);

        height: calc(100% - 16px - 16px);
    }
    &--bottom-left {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_left.png);

    }
    &--bottom-loop {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-loop_bottom.png);
        width: calc(100% - 16px - 16px);  
    }
    &--bottom-right {
        display:block;
        width: 16px;
        height: 16px;
        background: url(../../dist/assets/images/rank_border/rank_border-corner_bottom_right.png);

    }

    @include grid-media($portrait) {
        width: 690px;
        height: 372px;
        &--top-left, &--top-right, &--top-loop, 
        &--left-loop, &--right-loop,
        &--bottom-left, &--bottom-right, &--bottom-loop {
            // display: none;
        }

        &--content {
            // background: transparent;
            // width: 100%;
            // height: 100%;
        }
    }
}

