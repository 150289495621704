@import "../configs/mixins/clear_default";

.gallery {
    position: relative;
    height: 1678px;
    background: transparent;
    pointer-events: none;
    margin-top: -180px;

    @include grid-media($portrait) {
        height: 1888px; 
        padding-top: 0px;
        margin-top: 0px; 
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }

    }

    &__content {
        position: relative;
        height: 100%;

        // pointer-events: all;

        &:before {
            content: "";
            display: block;
            height: 190px;
            width: 100%;

            @include grid-media($portrait) {
                display: none;
            }
        }

        

        .swiper-container {
            pointer-events: all;
        }

        .swiper-pagination-listGallery {
            @include clear_swiper_pagination;
            position: static;
            z-index: 5;

            display: flex;
            // justify-content: center;

            .swiper-pagination-bullet {
                @include clear_swiper_pagination_bullet;
                width: 16px;
                height: 16px;
                background: #3355ab;
                transform: rotate(45deg);
                margin: 0 16px;

                &-active {
                    background: #3868e0;
                    outline: 2px solid #6e98f9;
                }
            }
        }

        .swiper-button-prev-listGallery, .swiper-button-next-listGallery {
            @include clear_swiper_navigation;
            display: block;
            position: static;
            pointer-events: all; 
            margin: 0 16px;

            &:after {
                display: none;
            }
        }

        .swiper-button-prev-listGallery {
            @include sprite($page-prev);
        }


        .swiper-button-next-listGallery { 
            @include sprite($page-next);
        }

    }

    &__wrapper {
        pointer-events: all;
        position: absolute;

        top: 610px;
        left: calc(50% - 605px);
        width: 925px;
        height: 620px;

        @include grid-media($portrait) {
            width: 100%;
            left: 0;
            top: 320px;
        }
    }

    &__list {
        display: block;
        position: relative;
        width: 100%;
        height: 550px;
        @include grid-media($portrait) {
            height: 1150px;
        }
    }

    &__item {
        position: absolute;

        &:nth-child(n+3) {
            .border_gallery {
                width: 290px;
                height: 170px;
            }
        }

        &:nth-child(1) {
            top: 0px;
            left: calc(50% - 460px);
        }

        &:nth-child(2) {
            top: 80px;
            left: calc(50% + 30px);
        }

        &:nth-child(3) {
            top: 360px;
            left: calc(50% - 460px);
        }

        &:nth-child(4) {
            top: 360px;
            left: calc(50% - 145px);
        }

        &:nth-child(5) {
            top: 360px;
            left: calc(50% + 170px);
        }

        @include grid-media($portrait) {
            position: static;

            &, &:nth-child(3) {
                .border_gallery {
                    width: 690px;
                    height: 372px;
                    margin-bottom: 12px;
                }
            }
            &:nth-child(n+4) {
                display: none;
            }
        }

        a {
            &.youtube {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                img {
                    filter: brightness(80%); 
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    @include sprite($video);
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%) scale(.4);
                    transform-origin: center center;
                }
            }
        }


    }

    &__page {
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
    }
}
