
.char {
    z-index: 2;

    pointer-events: all;

    &__single {
        width: 100%;
        height: 1160px;
        position: relative;

        @include grid-media($portrait) {
            height: 988px;
        }

        .thumbnail {
            position: absolute;
            // bottom: 100px;
            // left: calc(50% - 380px);
            bottom: 0px;
            left: calc(50% - 712px);

            // width: 1200px;
            // height: 1200px;

            @include grid-media($portrait) {
                bottom: -4px;
                left: auto;
                right: 0;
            }
        
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        
            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .headline {
            display: block;
            position: absolute;
            left: calc(50% + 200px);
            top: 0;
            width: 420px;
            height: 201px;
            background: url(../../dist/assets/images/bg/char-name.png);
            background-repeat: no-repeat;
            pointer-events: all;
            padding: 68px 0 50px 40px;
            box-sizing: border-box;

            @include grid-media($portrait) {
                left: 20px;
            }

            &:before {

            }
            .name {
                display: block;
                width: 100%;
                font-family: Cinzel;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 62px; 
                text-align: center;
                color: #4c76df;

                &--long {
                    font-size: 44px;
                    line-height: 62px;
                }
            }
            .tribe {
                display: block;
                width: 100%;
                font-family: Cinzel;
                font-size: 24px; 
                text-align: center;
                color: #5181b2;

                &:before, &:after {
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #5181b2;
                    position: relative;
                    transform: rotate(45deg);
                    top: -6px;

                }
                &:before {
                    left: -15px;
                }
                &:after {
                    right: -15px;
                }
            }
        }

        .info {
            display: block;
            position: absolute;
            left: calc(50% + 230px);
            top: 190px;
            width: 390px;
            height: 521px;  
            background: url(../../dist/assets/images/bg/char-info.png);
            background-repeat: no-repeat;
            pointer-events: all;
            box-sizing: border-box;
            padding: 50px 30px;

            @include grid-media($portrait) {
                left: 0;
                background: transparent;
            }

            .description, .weapon, .difficulty {
                font-size: 22px;
                font-family: SukhumvitSet;
                line-height: 36px;
                color: #3355ab;

                strong {
                    font-size: 28px;
                    font-weight: bold;
                }

                .icon {
                    display: inline-block;
                    svg {
                        path {
                            fill: #3355ab;
                        }
                    }
                }
            }

            .feature {
                display: flex;
                justify-content: center;

                @include grid-media($portrait) {
                    justify-content: flex-start;
                    img {
                        width: 220px;
                        height: auto;
                    }
                }

            }
        }

        .clip {
            display: block;
            pointer-events: all;
            position: absolute;
            top: 480px;
            left: calc(50% - 540px);
            @include sprite($video);

            &:hover {
                filter: brightness(120%);
            }

            @include grid-media($portrait) {
                top: 620px;
                left: calc(50% - 314px);
            }
        }
    }

    &__page {
        @include sprite($char-bar-bg);

        position: absolute;
        top: 35px;
        left: calc(50% - 558px);
        z-index: 10;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include grid-media($portrait) {
            position: static;
            background: url(../../dist/assets/images/bg/char-page.jpg);
            height: 130px;
            width: 100%; 

        }
    }

    

}