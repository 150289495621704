.article {
    position: relative;
    padding: 42px 188px;
    font-family: $font-primary;
    font-size: 17px;
    line-height: 22px;
    text-align: left;

    $content_color: #545454;
    $headlinea_color: #d09240;
    $headlineb_color: #36374d;
    color: $content_color;
    $block_space: 22px;

    @include grid-media($portrait) {
        padding: 42px 10px;
        font-size: 26px;
        line-height: 32px;
    }

    p {
        font-size: 1em;
        line-height: 1.6em; 
        margin-bottom: $block_space;
    }
    h2 {
       color: $headlinea_color;
       font-weight: bold; 
       font-size: 1.6em;
       line-height: 1.8em;
       margin-bottom: $block_space;

       &.headline {
        font-size: 2em;
        line-height: 2.2em;
       }
    }
    h3 {
        font-size: 1.4em;
        line-height: 1.6em;
        color: $headlinea_color;
        font-weight: bold;
        margin-bottom: $block_space;
    }
    h4 {
        font-size: 1.4em;
        line-height: 1.6em;
        color: $headlinea_color;
        font-weight: bold;
        margin-bottom: $block_space;
    }
    strong {
        font-weight: bold;
    }
    a {
        color: $headlinea_color;
        font-style: italic;
        text-decoration: none;;
    }


    ul {
        margin-bottom: $block_space;
        & > li {
            position: relative;
            padding-left: 20px;
            font-size: 1em;
            line-height: 1.6em; 
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 6px;
                
                // default

                background: $content_color;
                width: 6px;
                height: 1px;
                top: 16px;
            }
        }
    }

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto $block_space;
    }

    // SPECIAL BUILT - IN

    &__list {
        &__dash {
            & > li {
                &:before {
                    background: $content_color;
                    width: 6px;
                    height: 1px;
                    top: 16px;
                }
            }
        }
        &__dot {
            & > li {
                &:before {
                    background: $content_color;
                    width: 8px;
                    height: 8px;
                    border-radius: 99px;
                    top: 16px;
                }
            }
        }
        &__check {
            & > li {
                &:before {
                    width: 8px;
                    height: 16px;
                    background: transparent;
                    border-width: 3px;
                    border-style: solid;
                    border-color: transparent $content_color $content_color transparent;
                    transform: rotate(45deg);
                    top: 8px;
                }
            }
        }
    }


    &--detail {
        font-size: 20px;
        line-height: 28px;
        color: $gray-darker;
        text-align: center;

        @include grid-media($mobile, $tablet) {
            font-size: 15px;
            line-height: 24px;
            text-align: left;
        }

        h2 {
            font-size: 28px;
            line-height: 34px;
            color: $gray-darker;

            @include grid-media($mobile, $tablet) {
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                text-align: left;
            }
        }

        h3 {
            font-size: 22px;
            line-height: 26px;
            color: $white;
            padding: 20px 20px;

            @include grid-media($mobile, $tablet) {
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                text-align: left;
            }
        }
    }

    // TABLE

	table {
        margin: 0 auto $block_space;
		border-collapse: collapse;		

		tr:nth-child(odd),
		tr.odd,
		tr.OddRow {
			background: #fff;
		}
		tr:nth-child(even),
		tr.even,
		tr.EvenRow {
			background: #e9f1f8;
		}

		td,th {
			border: 1px solid #25358b;
			padding: 3px 10px;		
		}

		th {
			color: #fff;
			font-weight: bolder;
			padding: 5px 10px;		
			background: #25358b;
			text-align: center;			
		}
	}


}
