* {
    outline: none;
}

.wrapper {
    transform-origin: top left;
    &--fixed {
        position: fixed;
        width: 100%;
        height: 100%;
        pointer-events: none;
        top: 0;
        left: 0; 
        z-index: 50;
    }

    &.wrapperScale {
        width: 2100px;
        height: 1220px;

        @include grid-media($portrait) {
            width: 768px;
            height: 400px;
            
        }
    }
}


.container {
    display: block;
    margin: 0 auto;
    width: 1200px;
    max-width: 96%;

    // @include grid-media($mobile) {
    //     width: 96%; 
    // }
    // @include grid-media($tablet) {
    //     width: 724px;
    // }
    // @include grid-media($desktop) { 
    //     width: 1150px;
    // }

    &.container--full {
        width: 96%;

        @include grid-media($mobile, $tablet, $desktop) {
            width: 96%;
        }
    }
}

$grid-mobile: (
  columns: 12,
  gutter: 6px,
);

$grid-tablet: (
  columns: 12,
  gutter: 12px,
);

$grid-desktop: (
  columns: 12,
  gutter: 24px,
);

$grid-nogutters: (
  columns: 12,
  gutter: 0px,
);

.row {
    @include grid-media($mobile) {
        @include cxGrid($grid-mobile);
    }
    @include grid-media($tablet) {
        @include cxGrid($grid-tablet);
    }
    @include grid-media($desktop) {
        @include cxGrid($grid-desktop);
    }

    &--no_gutters {
        @include cxGrid($grid-nogutters);
    }
}

.section {
    &__background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}