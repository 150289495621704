@mixin clear_swiper_pagination_bullet {
    opacity: 1;
    border-radius: 0;
    background: transparent;
    width: initial;
    height: initial;
}

@mixin clear_swiper_pagination {
    bottom: initial;
    left: initial;
    left: initial;
    right: initial;
    top: initial;
    bottom: initial;
    
}

@mixin clear_swiper_navigation {
    
}