.title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    max-width: calc(100% - 16px * 2);
    margin: 0 auto;
    pointer-events: all;

    .label {
        text-align: center;
    }

    // style 
    &--sub {
        .label {
            display: inline-block;
            font-family: Oswald;
            font-size: 40px;
            line-height: 48px;
            text-transform: uppercase;
            color: #ae632c;
            position: relative;
            padding: 0 20px;
            

            &:before, &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: #ae632c;
                position: absolute;
                top: calc(50% - 2px);
                transform: rotate(45deg);
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }   
        
    }
    &--headline {
        .label {
            display: inline-block;
            font-family: Oswald;
            font-size: 60px;
            line-height: 68px;
            text-transform: uppercase;
            color: #b98300;
            position: relative;
            padding: 0 20px;

            &:before, &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: #b98300;
                position: absolute;
                top: calc(50% - 2px);
                transform: rotate(45deg);
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }   
        
    }
    &--popup {
        .label {
            display: inline-block;
            font-family: Oswald;
            font-size: 50px;
            line-height: 70px;
            text-transform: uppercase;
            font-weight: bold;
            color: #bf4009;
            position: relative;
            padding: 0 20px;

            &:before, &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: #bf4009;
                position: absolute;
                top: calc(50% - 2px);
                transform: rotate(45deg);
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }   
        
    }

    &--tabsnews {
        display: block;
        position: relative;
        pointer-events: all;
        border-bottom: 1px solid #c9ae81;
        margin-bottom: 16px;

        @include grid-media($portrait) {
            border-bottom: 0px;
        }

        &:before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background: #c9ae81;
            transform: rotate(45deg);
            position: absolute;
            top: 91%;
            left: 0;

            @include grid-media($portrait) {
                display: none;
            }
        }

        ul {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            li {
                a {
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                    display: block;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 17px;

                    text-decoration: none;

                    @include grid-media($portrait) {
                        color: #6c93d5;
                        font-size: 32px;
                        line-height: 42px;
                        height: 50px;
                    }

                    &.active {
                        position: relative;

                        

                        &:after {
                            content: "";
                            display: block;
                            width: 0px;
                            height: 0px; 
                            border-width: 5px;
                            border-style: solid;
                            border-color: #c9ae81 transparent transparent #c9ae81;
                            position: absolute;
                            transform: rotate(45deg);
                            top: calc(100% - 5px);
                        }

                        @include grid-media($portrait) {
                            color: #2955b4;
                            font-weight: bold;

                            &:after {
                                width: 100%;
                                height: 100%;
                                border: 1px solid #1656b2;
                                transform: rotate(0deg);
                                top: 0;
                                left: 0;
                                border-radius: 12px;
                            }
                        }
                    }

                    &:hover {
                        position: relative;

                        &:after {
                            content: "";
                            display: block;
                            width: 0px;
                            height: 0px; 
                            border-width: 5px;
                            border-style: solid;
                            border-color: #eee transparent transparent #eee;
                            position: absolute;
                            transform: rotate(45deg);
                            top: calc(100% - 5px);
                        }
                    }
                }

                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }

    &--tabssub {
        display: block;
        position: relative;
        pointer-events: all;

        ul {
            width: 100%;
            display: flex;
            justify-content: center;

            li {
                a {
                    font-size: 24px;
                    font-weight: 400;
                    display: block;
                    height: 44px;

                    margin: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 17px;
                    color: #6c93d5;

                    text-decoration: none;

                    @include grid-media($portrait) {
                        font-size: 32px;
                        line-height: 42px;
                        height: 50px;
                    }

                    &.active {
                        position: relative;
                        color: #2955b4;
                        font-weight: bold;
                        

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border: 2px solid #2955b4;
                            
                            top: 0;
                            left: 0;
                            border-radius: 12px;
                        }
                    }

                    &:hover {
                        position: relative;
                        

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border: 1px solid #9bbbf0;
                            top: 0;
                            left: 0;
                            border-radius: 12px;
                        }
                    }
                }
            }
        }
    }

    &--tabsrank {
        display: block;
        position: relative;
        pointer-events: all;
        width: 1120px;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 60px;
            border-width: 1px;
            border-style: solid;
            border-color: #b39b73 #b39b73 transparent #b39b73;
            position: absolute;
            top: 35%;
            left: 0;

            @include grid-media($portrait) {
                width: 80%;
                left: 10%;
            }
        }

        ul {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 20px;

            &:before, &:after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: #b39b73;

                position: absolute;
                top: 35%;

                @include grid-media($portrait) {
                    display: none;
                }

            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }

            li {
                margin: 0 7px;
                a {
                    color: #1e3f51;
                    font-size: 32px;
                    font-weight: 400;
                    display: block;
                    
                    @include sprite($rank-tab-bg);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text-decoration: none;

                    &:hover {
                        @include sprite($rank-tab-bg-hov);
                        color: #ffffff;
                    }

                    &.active {
                        @include sprite($rank-tab-bg-hov);
                        color: #ffffff;
                        &:hover {
                            filter: brightness(1.3);
                        }
                    }
                }

                @include grid-media($portrait) {

                    &:nth-child(1), &:nth-child(3) {
                        transform: scale(.5);
                    }
                    &:nth-child(1) {
                        transform-origin: center right;
                    }
                    &:nth-child(3) {
                        transform-origin: center left;
                    }
                }
            }
        }
    }

    &--tabsgallery {
        display: block;
        position: relative;
        pointer-events: all;
        width: 1200px;
        height: 50px;
        top: 80px;

        @include grid-media($portrait) {
            top: 0;
        }

        &:before {
            content: "";
            display: block;
            @include sprite($gallery-tab-line);

            position: absolute;
            top: 24px;
            left: -58px;

            @include grid-media($portrait) {
                left: 0;
            }
        }

        ul {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 20px;

            @include grid-media($portrait) {
                justify-content: center;
            }

            li {
                margin: 0 7px;
                a {
                    color: #24344d;
                    font-size: 28px;
                    font-weight: 400;
                    display: block;
                    
                    @include sprite($gallery-tab-bg);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text-decoration: none;

                    &:hover {
                        @include sprite($gallery-tab-bg-hov);
                        color: #24344d;
                    }

                    &.active {
                        @include sprite($gallery-tab-bg-hov);
                        color: #24344d;
                        &:hover {
                            // filter: brightness(1.3);
                        }
                    }
                }
            }
        }
    }


    &--main {
        z-index: 3;
        height: 152px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            @include sprite($title-bg);
        }

        .label {
            display: inline-block;
            font-family: SukhumvitSet;
            font-size: 72px;
            line-height: 86px;
            font-weight: bold;
            text-transform: uppercase;
            color: #fff;
            padding: 0 20px;
        }   
    }
}